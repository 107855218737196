import {useAtom} from 'jotai';
import {dishesLoadingAtom, displayDishesAtom} from 'pages/GuestPage/data';
import Dish from 'pages/GuestPage/Menu/Dishes/Dish';
import Loader from 'pages/GuestPage/Menu/Dishes/Loader';
import {memo} from 'react';
import {useTransition, animated} from 'react-spring';

import {Box, Container, Stack, Typography} from '@mui/material';

function Dishes() {
  const [dishes] = useAtom(displayDishesAtom);
  const [loading] = useAtom(dishesLoadingAtom);
  const transitions = useTransition(dishes, {
    from: {opacity: 0},
    enter: {opacity: 1},
    trail: 50 // Interval time
  });

  return (
    <Container>
      <Stack spacing={1} sx={{py: 2}}>
        {loading && <Loader />}
        {!loading && dishes.length === 0 && (
          <Box py={1}>
            <Typography>没有符合搜索的菜品</Typography>
          </Box>
        )}
        {!loading &&
          dishes.length > 0 &&
          transitions((style, cafeDish) => (
            <animated.div style={style}>
              <Dish key={cafeDish.dishId} cafeDish={cafeDish} />
            </animated.div>
          ))}
      </Stack>
    </Container>
  );
}

export default memo(Dishes);
