import {useAtom} from 'jotai';
import CafeAddress from 'pages/OwnerPage/CafePanel/CafeAddress';
import CafeHours from 'pages/OwnerPage/CafePanel/CafeHours';
import CafeIntro from 'pages/OwnerPage/CafePanel/CafeIntro';
import CafeName from 'pages/OwnerPage/CafePanel/CafeName';
import CafePhone from 'pages/OwnerPage/CafePanel/CafePhone';
import {
  cafeAddressAtom,
  cafeHoursAtom,
  cafeIntroAtom,
  cafeNameAtom,
  cafeOperationsAtom,
  cafePhoneAtom
} from 'pages/OwnerPage/CafePanel/data';
import {cafeIdAtom} from 'pages/OwnerPage/data';
import {memo, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {CafeData} from 'utils/cafe-data';
import {api} from 'utils/ui-client';

import {Box, Button, Stack} from '@mui/material';
import {dishEditProcessingAtom} from '../DishesPanel/EditDish/data';
import WithLoading from 'pages/Common/WithLoading';

function CafePanel() {
  const [cafeId] = useAtom(cafeIdAtom);
  const [cafeName] = useAtom(cafeNameAtom);
  const [cafeHours] = useAtom(cafeHoursAtom);
  const [cafeAddress] = useAtom(cafeAddressAtom);
  const [cafeIntro] = useAtom(cafeIntroAtom);
  const [cafePhone] = useAtom(cafePhoneAtom);
  const [processing, setProcessing] = useAtom(dishEditProcessingAtom);
  const [operations, setCafeOperations] = useAtom(cafeOperationsAtom);

  const navigate = useNavigate();

  const _getCafeData = (): CafeData => {
    return {
      cafeId,
      name: {cn: cafeName.cn.trim(), en: cafeName.en.trim()},
      hours: cafeHours.trim(),
      address: cafeAddress.trim(),
      intro: cafeIntro.trim(),
      phone: cafePhone.trim()
    };
  };

  const onSave = async () => {
    setProcessing(true);
    const _cafe = _getCafeData();
    await api.dataClient.put(`/cafe/${_cafe.cafeId}`, _cafe);
    setProcessing(false);
  };

  const onDelete = async () => {
    setProcessing(true);
    // 先删除关系document
    const cafeTables = await api.dataClient
      .get(`/cafe_tables/${cafeId}`)
      .then(({data}) => data);
    for (const table of cafeTables) {
      await api.dataClient.delete(`/cafe_table/${table.tableId}`);
    }
    const cafeDishes = await api.dataClient
      .get(`/cafe_dishes/${cafeId}`)
      .then(({data}) => data);
    for (const dish of cafeDishes) {
      // 这个里面会handle cloud storage的对应的文件删除
      await api.dataClient.delete(`/cafe_dish/${dish.dishId}`);
    }
    // 再删除cafe document
    await api.dataClient.delete(`cafe/${cafeId}`);
    setProcessing(false);
    navigate('/');
  };

  const _onDirtySave = async () => {
    await onSave();
    setCafeOperations(_ => _.slice(1));
  };

  useEffect(() => {
    if (operations.length === 0) return;
    _onDirtySave();
    // eslint-disable-next-line
  }, [operations]);

  return (
    <Stack spacing={1}>
      <CafeName />
      <CafePhone />
      <CafeAddress />
      <CafeHours />
      <CafeIntro />
      <Stack direction='row' spacing={1}>
        <Box sx={{flexGrow: 1}} />
        <WithLoading loading={processing}>
          <Link to='/'>
            <Button>返回首页</Button>
          </Link>
        </WithLoading>
        <WithLoading loading={processing}>
          <Button onClick={onDelete}>删除</Button>
        </WithLoading>
        <WithLoading loading={processing}>
          <Button onClick={onSave}>保存</Button>
        </WithLoading>
      </Stack>
    </Stack>
  );
}

export default memo(CafePanel);
