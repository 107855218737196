import {useAtom} from 'jotai';
import {guestOrderAtom} from 'pages/GuestPage/data';
import {memo} from 'react';
import {FaMinus, FaPlus} from 'react-icons/fa';
import {OrderDish} from 'utils/cafe-data';

import {Box, IconButton, Stack, Typography} from '@mui/material';

function DishAction({orderDish}: {orderDish: OrderDish}) {
  const [order, setOrder] = useAtom(guestOrderAtom);

  const onAdd = () => {
    const _order = {...order};
    for (const dish of _order.dishes) {
      if (dish.name.cn !== orderDish.name.cn) continue;
      dish.quantity++;
    }
    setOrder(_order);
  };

  const onReduce = () => {
    const _order = {...order};
    for (const dish of _order.dishes) {
      if (dish.name.cn !== orderDish.name.cn) continue;
      dish.quantity--;
    }
    _order.dishes = _order.dishes.filter(({quantity}) => quantity !== 0);
    setOrder(_order);
  };

  const quantity: number = !!orderDish ? orderDish.quantity : 0;

  return (
    <Stack direction='row' alignItems='center' spacing={1}>
      <Box sx={{flexGrow: 1}} />
      <IconButton
        color='secondary'
        disabled={quantity === 0}
        onClick={onReduce}>
        <FaMinus />
      </IconButton>
      <Typography fontWeight='bold'>{quantity}</Typography>
      <IconButton color='primary' onClick={onAdd}>
        <FaPlus />
      </IconButton>
    </Stack>
  );
}

export default memo(DishAction);
