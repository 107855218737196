import {useAtom} from 'jotai';
import {guestOrderAtom} from 'pages/GuestPage/data';
import DishAction from 'pages/GuestPage/Menu/Dishes/DishAction';
import DishBadges from 'pages/GuestPage/Menu/Dishes/DishBadges';
import DishImage from 'pages/GuestPage/Menu/Dishes/DishImage';
import {memo} from 'react';

import {Box, Grid, Stack, Typography} from '@mui/material';
import {CafeDish} from 'utils/cafe-data';

function Dish({cafeDish}: {cafeDish: CafeDish}) {
  const [order] = useAtom(guestOrderAtom);
  const isSelect = (CafeDish: CafeDish): boolean => {
    const orderDish = order.dishes.find(
      ({dishId}) => dishId === CafeDish.dishId
    );
    return !!orderDish;
  };
  return (
    <Box
      key={cafeDish.dishId}
      sx={{
        py: 1,
        transition: ({transitions}) => transitions.create('all'),
        bgcolor: isSelect(cafeDish) ? 'info.light' : 'white',
        borderRadius: 1
      }}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <DishImage cafeDish={cafeDish} />
        </Grid>

        <Grid item xs>
          <Stack sx={{height: '100%'}}>
            <Typography fontWeight='bold'>{cafeDish.name.cn}</Typography>
            <Typography variant='body2'>{cafeDish.name.en}</Typography>
            {cafeDish.status === 'sold out' && (
              <Typography color='secondary' variant='body2' fontWeight='bold'>
                今日售罄
              </Typography>
            )}
            <DishBadges cafeDish={cafeDish} />
            <Box sx={{flexGrow: 1}} />
            <DishAction cafeDish={cafeDish} />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}

export default memo(Dish);
