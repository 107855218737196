import {useAtom} from 'jotai';
import {cafeIdAtom} from 'pages/OwnerPage/data';
import {
  tableCapacityAtom,
  tableEditProcessingAtom,
  tableIdAtom,
  tableNameAtom,
  tableNoteAtom,
  tableOperationsAtom
} from 'pages/OwnerPage/TablesPanel/EditTable/data';
import EditCapacity from 'pages/OwnerPage/TablesPanel/EditTable/EditCapacity';
import EditName from 'pages/OwnerPage/TablesPanel/EditTable/EditName';
import EditNote from 'pages/OwnerPage/TablesPanel/EditTable/EditNote';
import {memo, useEffect} from 'react';
import {CafeTable} from 'utils/cafe-data';

import {Box, Button, Stack} from '@mui/material';
import WithLoading from 'pages/Common/WithLoading';

function EditTable({
  onSaveTable,
  onDeleteTable
}: {
  onSaveTable: (table: CafeTable) => void;
  onDeleteTable: (tableId: string) => void;
}) {
  const [cafeId] = useAtom(cafeIdAtom);
  const [tableId, setTableId] = useAtom(tableIdAtom);
  const [tableName, setTableName] = useAtom(tableNameAtom);
  const [tableCapacity, setTableCapacity] = useAtom(tableCapacityAtom);
  const [tableNote, setTableNote] = useAtom(tableNoteAtom);
  const [operations, setTableOperations] = useAtom(tableOperationsAtom);
  const [processing] = useAtom(tableEditProcessingAtom);

  const _getCafeTable = (): CafeTable => {
    return {
      cafeId,
      tableId,
      tableName: tableName.trim(),
      capacity: tableCapacity,
      note: tableNote.trim()
    };
  };

  const onSave = async () => {
    await onSaveTable(_getCafeTable());
    // Exit
    setTableId('');
    setTableName('');
    setTableCapacity(0);
    setTableNote('');
  };

  const onDelete = () => {
    onDeleteTable(tableId);
  };

  const _onDirtySave = async () => {
    await onSaveTable(_getCafeTable());
    setTableOperations(_ => _.slice(1));
  };

  useEffect(() => {
    if (operations.length === 0) return;
    _onDirtySave();
    // eslint-disable-next-line
  }, [operations]);

  return (
    <Stack spacing={1}>
      <EditName />
      <EditCapacity />
      <EditNote />
      <Stack direction='row' spacing={1}>
        <Box sx={{flexGrow: 1}} />
        <WithLoading loading={processing}>
          <Button onClick={onDelete}>删除</Button>
        </WithLoading>
        <WithLoading loading={processing}>
          <Button onClick={onSave}>保存</Button>
        </WithLoading>{' '}
      </Stack>
    </Stack>
  );
}

export default memo(EditTable);
