import {useAtom} from 'jotai';
import {cafeIdAtom} from 'pages/OwnerPage/data';
import {
  dishIdAtom,
  dishImageAtom,
  dishOperationsAtom
} from 'pages/OwnerPage/DishesPanel/EditDish/data';
import {memo, useRef, useState} from 'react';
import Cropper, {ReactCropperElement} from 'react-cropper';
import {DISH_IMG_H, DISH_IMG_W} from 'utils/cafe-data';
import {api} from 'utils/ui-client';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography
} from '@mui/material';

function EditImage2D() {
  const [cafeId] = useAtom(cafeIdAtom);
  const [dishId] = useAtom(dishIdAtom);
  const [dishImage, setDishImage] = useAtom(dishImageAtom);
  const [, setDishOperations] = useAtom(dishOperationsAtom);
  const [open, setOpen] = useState(false);
  const [image2D, setImage2D] = useState<string>('');
  const cropperRef = useRef<ReactCropperElement>(null);

  const onSetupDialog = (e: any) => {
    const files = e.target.files;
    const file = files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImage2D(reader.result as string);
      setOpen(true);
    };
    reader.readAsDataURL(file);
  };

  const upload2DImage = async () => {
    if (!cropperRef.current) return;
    if (dishImage.image2D.path) {
      await api.dataClient.delete(`/file/delete/${dishImage.image2D.path}`);
    }
    cropperRef.current.cropper
      .getCroppedCanvas({
        width: DISH_IMG_W,
        height: DISH_IMG_H,
        fillColor: 'white'
      })
      .toBlob((blob: any) => {
        const formData = new FormData();
        formData.append('folder', `${cafeId}/${dishId}`);
        formData.append('files', blob);
        api.fileClient.post('/file/upload', formData).then(({data}) => {
          setDishImage(_ => ({..._, image2D: data[0]}));
          setDishOperations(_ => [..._, '上传2D图片']);
        });
      }, 'image/jpeg');
    setOpen(false);
  };

  return (
    <Stack>
      <Typography variant='body2' fontWeight='bold'>
        2D图片
      </Typography>
      <Stack spacing={1}>
        {dishImage.image2D.url && (
          <Box
            component='img'
            loading='lazy'
            sx={{height: DISH_IMG_H, width: DISH_IMG_W, borderRadius: 1}}
            src={dishImage.image2D.url}
            alt=''
          />
        )}
        <input
          type='file'
          accept='image/*'
          onChange={onSetupDialog}
          style={{display: 'none'}}
          id='dish-2d-upload'
        />
        <label htmlFor='dish-2d-upload'>
          <Button component='span'>上传2D图片</Button>
        </label>
      </Stack>

      {image2D && (
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>剪裁并上传图片</DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <Cropper
                ref={cropperRef}
                src={image2D}
                style={{height: 400, width: '100%'}}
                aspectRatio={16 / 9}
                guides={false}
                viewMode={1}
              />

              <Stack direction='row' spacing={1}>
                <Box sx={{flexGrow: 1}} />
                <Button variant='outlined' onClick={() => setOpen(false)}>
                  取消
                </Button>
                <Button onClick={upload2DImage}>确认</Button>
              </Stack>
            </Stack>
          </DialogContent>
        </Dialog>
      )}
    </Stack>
  );
}

export default memo(EditImage2D);
