import {useAtom} from 'jotai';
import {
  cafeDishesAtom,
  dishesLoadingAtom,
  displayDishesAtom
} from 'pages/GuestPage/data';
import {memo, useState} from 'react';
import {FaTimes} from 'react-icons/fa';
import {GiOlive} from 'react-icons/gi';
import {useDebouncedCallback} from 'use-debounce';
import {scrollToTop} from 'utils/ui-theme';

import {Button, Icon, InputBase, Stack} from '@mui/material';
import {appLangAtom} from 'utils/app-data';
import {useTranslation} from 'react-i18next';

function SearchQuery() {
  const [cafeDishes] = useAtom(cafeDishesAtom);
  const [, setDisplayDishes] = useAtom(displayDishesAtom);
  const [loading, setLoading] = useAtom(dishesLoadingAtom);
  const [appLang, setAppLang] = useAtom(appLangAtom);
  const [query, setQuery] = useState('');
  const {t, i18n} = useTranslation();

  const search = () => {
    if (loading) return;
    setLoading(true);
    scrollToTop();
    const dishes = cafeDishes.filter(({name}) =>
      [name].join('\n').includes(query)
    );
    setDisplayDishes(dishes);
    setLoading(false);
  };

  const debounced = useDebouncedCallback(() => search(), 500);

  const onChange = (e: any) => {
    setQuery(e.target.value);
    debounced();
  };

  const onClear = () => {
    setQuery('');
    setDisplayDishes(cafeDishes);
  };

  const onSwitchLang = () => {
    if (appLang === 'cn') {
      setAppLang('en');
      i18n.changeLanguage('en');
    } else {
      setAppLang('cn');
      i18n.changeLanguage('cn');
    }
  };

  return (
    <InputBase
      value={query}
      onChange={onChange}
      fullWidth
      placeholder={`青苹果 - ${t('搜索菜单')}`}
      sx={{bgcolor: 'action.hover', px: 1, py: 0.5}}
      startAdornment={
        <Icon color='primary' sx={{mr: 1}}>
          <GiOlive />
        </Icon>
      }
      endAdornment={
        <Stack direction='row' spacing={0.5}>
          {!!query.trim() && (
            <Button
              onClick={onClear}
              sx={{minWidth: 'auto', p: 0.5}}
              variant='text'>
              <FaTimes />
            </Button>
          )}

          <Button sx={{py: 0.5}} onClick={onSwitchLang}>
            {t('语言')}
          </Button>
        </Stack>
      }
    />
  );
}

export default memo(SearchQuery);
