import {useAtom} from 'jotai';
import {
  dishSampleIdAtom,
  dishSampleProcessingAtom,
  dishSamplesAtom
} from 'pages/AdminPage/data';
import EditSample from 'pages/AdminPage/DishSamples/EditSample';
import ViewSample from 'pages/AdminPage/DishSamples/ViewSample';
import {memo} from 'react';
import {api} from 'utils/ui-client';

import {Container, Stack} from '@mui/material';
import {DishSample} from 'utils/cafe-data';

function DishSamples() {
  const [dishSampleId] = useAtom(dishSampleIdAtom);
  const [, setProcessing] = useAtom(dishSampleProcessingAtom);
  const [samples, setSamples] = useAtom(dishSamplesAtom);
  if (samples.length === 0) return <></>;

  const onDeleteSample = async (sampleId: string) => {
    setProcessing(true);
    await api.dataClient.delete(`/dish_sample/${sampleId}`);
    await api.dataClient
      .get('/dish_samples')
      .then(({data}) => setSamples(data));
    setProcessing(false);
  };

  const onSaveSample = async (_sample: DishSample) => {
    setProcessing(true);
    await api.dataClient.put(`/dish_sample/${_sample.sampleId}`, _sample);
    await api.dataClient
      .get('/dish_samples')
      .then(({data}) => setSamples(data));
    setProcessing(false);
  };

  return (
    <Container sx={{py: 2}}>
      <Stack spacing={2}>
        {samples.map(dishSample =>
          dishSample.sampleId === dishSampleId ? (
            <EditSample
              key={dishSample.sampleId}
              dishSample={dishSample}
              onDeleteSample={onDeleteSample}
              onSaveSample={onSaveSample}
            />
          ) : (
            <ViewSample
              key={dishSample.sampleId}
              dishSample={dishSample}
              onDeleteSample={onDeleteSample}
            />
          )
        )}
      </Stack>
    </Container>
  );
}

export default memo(DishSamples);
