import {useAtom} from 'jotai';
import {
  dishNameAtom,
  dishOperationsAtom
} from 'pages/OwnerPage/DishesPanel/EditDish/data';
import {memo, useEffect} from 'react';
import {useDebouncedCallback} from 'use-debounce';

import {Card, InputBase, Stack, Typography} from '@mui/material';

function EditName() {
  const [dishName, setDishName] = useAtom(dishNameAtom);
  const [, setOperations] = useAtom(dishOperationsAtom);
  const debounced = useDebouncedCallback(
    () => setOperations(_ => [..._, '保存菜名']),
    1000
  );

  const onChangeCn = (e: any) => {
    setDishName(_ => ({..._, cn: e.target.value}));
    debounced();
  };

  const onChangeEn = (e: any) => {
    setDishName(_ => ({..._, en: e.target.value}));
    debounced();
  };

  // 结束
  useEffect(() => {
    return () => debounced.flush();
  }, [debounced]);

  return (
    <Stack>
      <Typography variant='body2' fontWeight='bold'>
        菜名
      </Typography>

      <Stack spacing={1}>
        <Card variant='outlined'>
          <InputBase
            value={dishName.cn}
            onChange={onChangeCn}
            fullWidth
            placeholder='菜式中文名'
            sx={{bgcolor: 'action.hover', px: 1}}
          />
        </Card>

        <Card variant='outlined'>
          <InputBase
            value={dishName.en}
            onChange={onChangeEn}
            fullWidth
            placeholder='Dish English Name'
            sx={{bgcolor: 'action.hover', px: 1}}
          />
        </Card>
      </Stack>
    </Stack>
  );
}

export default memo(EditName);
