import {atom} from 'jotai';
import {CafeDish, CafeTable} from 'utils/cafe-data';

export const cafeIdAtom = atom('');

export const cafeDishesAtom = atom<CafeDish[]>([]);
export const cafeTablesAtom = atom<CafeTable[]>([]);

// UI
export type OwnerTab = 'cafe' | 'tables' | 'dishes';
export const ownerTabAtom = atom<OwnerTab>('cafe');
