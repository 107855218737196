import {useAtom} from 'jotai';
import {dishSampleProcessingAtom, dishSamplesAtom} from 'pages/AdminPage/data';
import WithLoading from 'pages/Common/WithLoading';
import {memo, useEffect, useState} from 'react';
import {FaSearch} from 'react-icons/fa';
import {api} from 'utils/ui-client';

import {Button, Card, InputBase} from '@mui/material';

function Search() {
  const [query, setQuery] = useState('');
  const [, setSamples] = useAtom(dishSamplesAtom);
  const [processing, setProcessing] = useAtom(dishSampleProcessingAtom);

  // Fetch samples based on the query criteria
  const onSearch = () => {
    setProcessing(true);
    api.dataClient
      .get('/dish_samples', {params: {query}})
      .then(({data}) => setSamples(data))
      .catch(err => console.error(err))
      .finally(() => setProcessing(false));
  };

  const onChange = (e: any) => {
    setQuery(e.target.value);
  };

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      onSearch();
    }
  };

  // 初始化
  useEffect(() => {
    onSearch();
    // eslint-disable-next-line
  }, []);

  return (
    <Card variant='outlined' sx={{p: 1}}>
      <InputBase
        placeholder='搜索菜式或菜名'
        value={query}
        onChange={onChange}
        onKeyDown={onKeyDown}
        sx={{bgcolor: 'action.hover'}}
        endAdornment={
          <WithLoading loading={processing}>
            <Button onClick={onSearch} sx={{minWidth: 'auto', p: 1}}>
              <FaSearch />
            </Button>
          </WithLoading>
        }
      />
    </Card>
  );
}

export default memo(Search);
