import {useAtom} from 'jotai';
import {dishSampleIdAtom} from 'pages/AdminPage/data';
import {
  dishImageAtom,
  dishNameAtom
} from 'pages/AdminPage/DishSamples/EditSample/data';
import {memo} from 'react';
import {DISH_IMG_H, DISH_IMG_W, DishSample} from 'utils/cafe-data';

import {Box, Button, Card, Stack, Typography} from '@mui/material';

function ViewSample({
  dishSample,
  onDeleteSample
}: {
  dishSample: DishSample;
  onDeleteSample: (sampleId: string) => void;
}) {
  const [dishSampleId, setDishSampleId] = useAtom(dishSampleIdAtom);
  const [, setDishImage] = useAtom(dishImageAtom);
  const [, setDishName] = useAtom(dishNameAtom);
  const onDelete = () => {
    onDeleteSample(dishSample.sampleId);
  };
  const onEdit = () => {
    setDishSampleId(dishSample.sampleId);
    setDishImage(dishSample.image);
    setDishName(dishSample.name);
  };

  return (
    <Card sx={{p: 2}} variant='outlined'>
      <Stack spacing={1}>
        <Typography variant='body2'>{dishSample.sampleId}</Typography>
        <Typography variant='body2'>{dishSample.name.cn}</Typography>
        <Typography variant='body2'>{dishSample.name.en}</Typography>
        {dishSample.image.image2D.url && (
          <Box
            component='img'
            loading='lazy'
            src={dishSample.image.image2D.url}
            alt=''
            sx={{height: DISH_IMG_H, width: DISH_IMG_W, borderRadius: 1}}
          />
        )}
        {dishSample.image.image3D.url && (
          <Box
            component='img'
            loading='lazy'
            src={dishSample.image.image3D.url}
            alt=''
            sx={{height: DISH_IMG_H, width: DISH_IMG_W, borderRadius: 1}}
          />
        )}
        {dishSampleId === '' && (
          <Stack direction='row' spacing={1}>
            <Box sx={{flexGrow: 1}} />
            <Button onClick={onDelete}>删除</Button>
            <Button onClick={onEdit}>编辑</Button>
          </Stack>
        )}
      </Stack>
    </Card>
  );
}

export default memo(ViewSample);
