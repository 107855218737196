import DishGuest from 'pages/GuestPage/Order/Dishes/DishGuest';
import DishOrders from 'pages/GuestPage/Order/Dishes/DishOrders';
import DishPayment from 'pages/GuestPage/Order/Dishes/DishPayment';
import {memo} from 'react';

import {Stack} from '@mui/material';

function Dishes() {
  return (
    <Stack sx={{py: 2}} spacing={3}>
      <DishOrders />
      <DishPayment />
      <DishGuest />
    </Stack>
  );
}

export default memo(Dishes);
