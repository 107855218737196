import {useAtom} from 'jotai';
import {guestTabAtom} from 'pages/GuestPage/data';
import {memo} from 'react';

import {Box, Button, Stack} from '@mui/material';

function Bottom() {
  const [, setTab] = useAtom(guestTabAtom);

  return (
    <Stack direction='row' alignItems='center' spacing={1} sx={{flexGrow: 1}}>
      <Box sx={{flexGrow: 1}} />
      <Button onClick={() => setTab('menu')}>{`返回菜单`}</Button>
      <Button onClick={() => {}}>{`提交订单`}</Button>
    </Stack>
  );
}

export default memo(Bottom);
