import {atom} from 'jotai';
import {CafeName} from 'utils/cafe-data';

export const cafeNameAtom = atom<CafeName>({cn: '', en: ''});
export const cafePhoneAtom = atom('');
export const cafeHoursAtom = atom('');
export const cafeIntroAtom = atom('');
export const cafeAddressAtom = atom('');

export const cafeOperationsAtom = atom<string[]>([]);
export const cafeEditProcessingAtom = atom(false);
