import {memo} from 'react';
import {GiOlive} from 'react-icons/gi';
import {Link, useLocation} from 'react-router-dom';

import {Box, Button, Container, Stack, Typography} from '@mui/material';

const routes = [
  {path: '/', label: '首页'},
  {path: '/admin', label: '管理员'},
  {path: '/guest', label: '顾客'}
];
function ErrorPage() {
  const location = useLocation();

  return (
    <Container sx={{mt: 1}}>
      <Stack direction='row' spacing={1}>
        <Stack justifyContent='center' sx={{color: 'primary.main'}}>
          <GiOlive size={24} />
        </Stack>
        <Box sx={{flexGrow: 1}} />
        {routes.map(({path, label}) => (
          <Link to={path} key={path}>
            <Button disabled={location.pathname === path}>{label}</Button>
          </Link>
        ))}
      </Stack>
      <Typography>这里显示错误页</Typography>
    </Container>
  );
}

export default memo(ErrorPage);
