import 'cropperjs/dist/cropper.css';
import './i18n';
import {Provider} from 'jotai';
import Router from 'pages/Router';
import {createRoot} from 'react-dom/client';
import reportWebVitals from 'reportWebVitals';
import {theme} from 'utils/ui-theme';

import {CssBaseline, ThemeProvider} from '@mui/material';

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider>
    <ThemeProvider theme={theme}>
      <Router />
      <CssBaseline />
    </ThemeProvider>
  </Provider>
);

reportWebVitals();
