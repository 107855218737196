import {useAtom} from 'jotai';
import Menu from 'pages/GuestPage/Menu';
import Order from 'pages/GuestPage/Order';
import {guestTabAtom} from 'pages/GuestPage/data';
import {memo, useEffect} from 'react';

import {cafeDishesAtom, displayDishesAtom} from 'pages/GuestPage/data';
import {genDish} from 'utils/cafe-data';

function GuestPage() {
  const [tab] = useAtom(guestTabAtom);
  const [, setCafeDishes] = useAtom(cafeDishesAtom);
  const [, setDisplayDishes] = useAtom(displayDishesAtom);

  // 初始化, 拿这家店的dishes menu
  useEffect(() => {
    const dishes = Array.from({length: 50}, () => genDish());
    // TODO: API
    setCafeDishes([...dishes]);
    setDisplayDishes([...dishes]);
    // eslint-disable-next-line
  }, []);
  return tab === 'menu' ? <Menu /> : <Order />;
}

export default memo(GuestPage);
