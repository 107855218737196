import {useAtom} from 'jotai';
import {guestOrderAtom} from 'pages/GuestPage/data';
import {memo} from 'react';

import {Card, InputBase, Stack, Typography} from '@mui/material';

function DishGuest() {
  const [order, setOrder] = useAtom(guestOrderAtom);
  const onNoteChange = (e: any) => {
    setOrder(_ => ({
      ..._,
      guest: {..._.guest, note: e.target.value}
    }));
  };

  const onPhoneChange = (e: any) => {
    setOrder(_ => ({
      ..._,
      guest: {..._.guest, phone: e.target.value}
    }));
  };

  return (
    <Stack>
      <Typography fontWeight='bold' color='primary' gutterBottom>
        备注信息
      </Typography>
      <Stack spacing={1}>
        <Card variant='outlined'>
          <InputBase
            value={order.guest.note}
            onChange={onNoteChange}
            fullWidth
            multiline
            minRows={2}
            placeholder='菜品特别需求 (如: 过敏)'
            sx={{bgcolor: 'action.hover', px: 1, py: 0.5}}
          />
        </Card>

        <Card variant='outlined'>
          <InputBase
            value={order.guest.phone}
            type='number'
            onChange={onPhoneChange}
            fullWidth
            placeholder='留下您的电话'
            sx={{bgcolor: 'action.hover', px: 1, py: 0.5}}
          />
        </Card>
      </Stack>
    </Stack>
  );
}

export default memo(DishGuest);
