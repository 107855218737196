import {useAtom} from 'jotai';
import CafePanel from 'pages/OwnerPage//CafePanel';
import {cafeIdAtom, OwnerTab, ownerTabAtom} from 'pages/OwnerPage//data';
import {
  cafeAddressAtom,
  cafeHoursAtom,
  cafeIntroAtom,
  cafeNameAtom,
  cafePhoneAtom
} from 'pages/OwnerPage/CafePanel/data';
import DishesPanel from 'pages/OwnerPage/DishesPanel';
import {dishIdAtom} from 'pages/OwnerPage/DishesPanel/EditDish/data';
import TablesPanel from 'pages/OwnerPage/TablesPanel';
import {tableIdAtom} from 'pages/OwnerPage/TablesPanel/EditTable/data';
import {memo, useEffect} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {api} from 'utils/ui-client';

import {Box, Container, Divider, Stack, Tab, Tabs} from '@mui/material';

function OwnerPage() {
  const [tab, setTab] = useAtom(ownerTabAtom);
  const [, setCafeId] = useAtom(cafeIdAtom);
  const [, setCafeName] = useAtom(cafeNameAtom);
  const [, setCafeIntro] = useAtom(cafeIntroAtom);
  const [, setCafeAddress] = useAtom(cafeAddressAtom);
  const [, setCafeHours] = useAtom(cafeHoursAtom);
  const [, setCafePhone] = useAtom(cafePhoneAtom);
  const [dishId, setDishId] = useAtom(dishIdAtom);
  const [tableId, setTableId] = useAtom(tableIdAtom);

  const navigate = useNavigate();
  const [search] = useSearchParams();
  const onTabSwitch = (_: any, _tab: OwnerTab) => {
    setTab(_tab);
    if (dishId) setDishId('');
    if (tableId) setTableId('');
  };

  const _injectCafeInfo = (cafeId: string) => {
    api.dataClient.get(`/cafe/${cafeId}`).then(({data}) => {
      setCafeId(cafeId);
      setCafeName(data.name);
      setCafeAddress(data.address);
      setCafeIntro(data.intro);
      setCafeHours(data.hours);
      setCafePhone(data.phone);
    });
  };

  // 初始化
  useEffect(() => {
    // const s = location.search
    const cafeId = search.get('cafeId');
    if (!cafeId) return navigate('/');
    _injectCafeInfo(cafeId);
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <Stack spacing={2}>
        <Box sx={{width: '100%'}}>
          <Tabs value={tab} onChange={onTabSwitch}>
            <Tab
              value='cafe'
              label='店铺'
              id='tab-cafe'
              aria-controls='panel-cafe'
            />
            <Tab
              value='tables'
              label='餐桌'
              id='tab-tables'
              aria-controls='panel-tables'
            />
            <Tab
              value='dishes'
              label='菜单'
              id='tab-dishes'
              aria-controls='panel-dishes'
            />
          </Tabs>
          <Divider />
        </Box>
        {tab === 'cafe' && <CafePanel />}
        {tab === 'tables' && <TablesPanel />}
        {tab === 'dishes' && <DishesPanel />}
      </Stack>
    </Container>
  );
}

export default memo(OwnerPage);
