import {useAtom} from 'jotai';
import {dishSampleIdAtom, dishSampleProcessingAtom} from 'pages/AdminPage/data';
import {memo, useEffect} from 'react';
import {DishSample} from 'utils/cafe-data';

import {Box, Button, Card, Stack, Typography} from '@mui/material';
import EditName from 'pages/AdminPage/DishSamples/EditSample/EditName';
import {
  dishImageAtom,
  dishNameAtom,
  sampleOperationsAtom
} from 'pages/AdminPage/DishSamples/EditSample/data';
import EditImage2D from 'pages/AdminPage/DishSamples/EditSample/EditImage2D';
import EditImage3D from 'pages/AdminPage/DishSamples/EditSample/EditImage3D';
import WithLoading from 'pages/Common/WithLoading';

function EditSample({
  dishSample,
  onDeleteSample,
  onSaveSample
}: {
  dishSample: DishSample;
  onDeleteSample: (sampleId: string) => void;
  onSaveSample: (sample: DishSample) => void;
}) {
  const [dishSampleId, setDishSampleId] = useAtom(dishSampleIdAtom);
  const [dishName] = useAtom(dishNameAtom);
  const [dishImage] = useAtom(dishImageAtom);
  const [operations, setSampleOperations] = useAtom(sampleOperationsAtom);
  const [processing] = useAtom(dishSampleProcessingAtom);
  const _getSample = (): DishSample => {
    return {
      sampleId: dishSampleId,
      name: dishName,
      image: dishImage
    };
  };

  const onSave = () => {
    onSaveSample(_getSample());
    setDishSampleId('');
  };

  const _onDirtySave = async () => {
    await onSaveSample(_getSample());
    setSampleOperations(_ => _.slice(1));
  };

  useEffect(() => {
    if (operations.length === 0) return;
    _onDirtySave();
    // eslint-disable-next-line
  }, [operations]);

  return (
    <Card sx={{p: 2}} variant='outlined'>
      <Stack spacing={1}>
        <Typography variant='body2'>{dishSample.sampleId}</Typography>
        <EditName />
        <EditImage2D />
        <EditImage3D />
        <Stack direction='row' spacing={1}>
          <Box sx={{flexGrow: 1}} />
          <WithLoading loading={processing}>
            <Button onClick={() => onDeleteSample(dishSample.sampleId)}>
              删除
            </Button>
          </WithLoading>

          <WithLoading loading={processing}>
            <Button onClick={onSave}>保存</Button>
          </WithLoading>
        </Stack>
      </Stack>
    </Card>
  );
}

export default memo(EditSample);
