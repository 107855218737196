import {useAtom} from 'jotai';
import {
  cafeOperationsAtom,
  cafePhoneAtom
} from 'pages/OwnerPage/CafePanel/data';
import {memo} from 'react';
import {useDebouncedCallback} from 'use-debounce';

import {Card, InputBase} from '@mui/material';

function CafePhone() {
  const [cafePhone, setCafePhone] = useAtom(cafePhoneAtom);
  const [, setOperations] = useAtom(cafeOperationsAtom);
  const debounced = useDebouncedCallback(
    () => setOperations(_ => [..._, '保存餐厅电话']),
    1000
  );
  const onChange = (e: any) => {
    setCafePhone(e.target.value);
    debounced();
  };

  return (
    <Card variant='outlined'>
      <InputBase
        value={cafePhone}
        onChange={onChange}
        fullWidth
        type='number'
        placeholder='餐馆电话'
        sx={{bgcolor: 'action.hover', px: 1, py: 0.5}}
      />
    </Card>
  );
}

export default memo(CafePhone);
