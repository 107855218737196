import TopNav from 'pages/Common/TopNav';
import SearchQuery from 'pages/GuestPage/Menu/Top/SearchQuery';
import {memo} from 'react';

import {Stack} from '@mui/material';

function Top() {
  return (
    <TopNav>
      <Stack spacing={1} sx={{flexGrow: 1, bgcolor: 'white', borderRadius: 1}}>
        <SearchQuery />
      </Stack>
    </TopNav>
  );
}

export default memo(Top);
