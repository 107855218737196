import {atom} from 'jotai';
import {DishCounter, DishImage, DishName, DishStatus} from 'utils/cafe-data';

export const dishIdAtom = atom('');
export const dishMenuIdAtom = atom(0);
export const dishCounterAtom = atom<DishCounter>({
  totalSold: 0,
  monthSold: 0,
  weekSold: 0,
  daySold: 0
});
export const dishNameAtom = atom<DishName>({cn: '', en: ''});
export const dishImageAtom = atom<DishImage>({
  image2D: {url: '', path: '', type: '', bytes: 0},
  image3D: {url: '', path: '', type: '', bytes: 0}
});
export const dishSpicinessAtom = atom(0);
export const dishStatusAtom = atom<DishStatus>('');
export const dishCategoriesAtom = atom<string[]>([]);
export const dishPriceAtom = atom(0);
export const dishDiscountAtom = atom(0);

export const dishOperationsAtom = atom<string[]>([]);
export const dishEditProcessingAtom = atom(false);
