import {useAtom} from 'jotai';
import {guestOrderAtom} from 'pages/GuestPage/data';
import {memo} from 'react';
import {FaMinus, FaPlus} from 'react-icons/fa';
import {CafeDish} from 'utils/cafe-data';

import {Box, IconButton, Stack, Typography} from '@mui/material';

function DishAction({cafeDish}: {cafeDish: CafeDish}) {
  const [order, setOrder] = useAtom(guestOrderAtom);
  const orderDish = order.dishes.find(({dishId}) => dishId === cafeDish.dishId);

  const onAdd = () => {
    if (!orderDish) {
      setOrder(_ => ({
        ..._,
        dishes: [
          ..._.dishes,
          {
            dishId: cafeDish.dishId,
            name: cafeDish.name,
            quantity: 1,
            price: cafeDish.price
          }
        ]
      }));
    } else {
      const _order = {...order};
      for (const dish of _order.dishes) {
        if (dish.name.cn !== cafeDish.name.cn) continue;
        dish.quantity++;
      }
      setOrder(_order);
    }
  };

  const onReduce = () => {
    const _order = {...order};
    for (const dish of _order.dishes) {
      if (dish.name.cn !== cafeDish.name.cn) continue;
      dish.quantity--;
    }
    _order.dishes = _order.dishes.filter(({quantity}) => quantity !== 0);
    setOrder(_order);
  };

  const quantity: number = !!orderDish ? orderDish.quantity : 0;
  if (cafeDish.status === 'sold out') return <></>;
  return (
    <Stack direction='row' alignItems='center' spacing={1}>
      <Box sx={{flexGrow: 1}} />
      {quantity > 0 && (
        <IconButton
          color='secondary'
          disabled={quantity === 0}
          onClick={onReduce}>
          <FaMinus />
        </IconButton>
      )}
      {quantity > 0 && <Typography variant='h6'>{quantity}</Typography>}
      <IconButton color='primary' onClick={onAdd}>
        <FaPlus />
      </IconButton>
    </Stack>
  );
}

export default memo(DishAction);
