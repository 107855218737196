import {Skeleton} from '@mui/material';
import {ReactElement, memo} from 'react';

function WithLoading({
  children,
  loading
}: {
  loading: boolean;
  children: ReactElement;
}) {
  return loading ? (
    <Skeleton variant='rounded'>{children}</Skeleton>
  ) : (
    <>{children}</>
  );
}

export default memo(WithLoading);
