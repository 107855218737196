import {useAtom} from 'jotai';
import {memo} from 'react';
import {CafeDish, DISH_IMG_H, DISH_IMG_W} from 'utils/cafe-data';

import {Box, Button, Chip, Stack, Typography} from '@mui/material';
import WithLoading from 'pages/Common/WithLoading';
import {
  dishCategoriesAtom,
  dishCounterAtom,
  dishDiscountAtom,
  dishEditProcessingAtom,
  dishIdAtom,
  dishImageAtom,
  dishMenuIdAtom,
  dishNameAtom,
  dishPriceAtom,
  dishSpicinessAtom,
  dishStatusAtom
} from 'pages/OwnerPage/DishesPanel/EditDish/data';
import {FaPepperHot} from 'react-icons/fa';

function ViewDish({
  cafeDish,
  onDeleteDish
}: {
  cafeDish: CafeDish;
  onDeleteDish: (dishId: string) => void;
}) {
  const [dishId, setDishId] = useAtom(dishIdAtom);
  const [, setDishMenuId] = useAtom(dishMenuIdAtom);
  const [, setDishCounter] = useAtom(dishCounterAtom);
  const [, setDishName] = useAtom(dishNameAtom);
  const [, setDishCategories] = useAtom(dishCategoriesAtom);
  const [, setDishImage] = useAtom(dishImageAtom);
  const [, setDishPrice] = useAtom(dishPriceAtom);
  const [, setDishStatus] = useAtom(dishStatusAtom);
  const [, setDishDiscount] = useAtom(dishDiscountAtom);
  const [, setDishSpiciness] = useAtom(dishSpicinessAtom);
  const [processing] = useAtom(dishEditProcessingAtom);

  const onEditClick = () => {
    setDishId(cafeDish.dishId);
    setDishMenuId(cafeDish.dishMenuId);
    setDishCounter(cafeDish.counter);
    setDishName(cafeDish.name);
    setDishCategories(cafeDish.categories);
    setDishImage(cafeDish.image);
    setDishPrice(cafeDish.price);
    setDishStatus(cafeDish.status);
    setDishDiscount(cafeDish.discount);
    setDishSpiciness(cafeDish.spiciness);
  };

  const onDelete = () => {
    onDeleteDish(cafeDish.dishId);
  };
  return (
    <Stack spacing={1}>
      <Stack>
        <Typography>{cafeDish.name.cn}</Typography>
        <Typography>{cafeDish.name.en}</Typography>
      </Stack>
      {cafeDish.image.image2D.url && (
        <Box
          component='img'
          sx={{width: DISH_IMG_W, height: DISH_IMG_H, borderRadius: 1}}
          src={cafeDish.image.image2D.url}
          alt=''
          loading='lazy'
        />
      )}
      <Stack direction='row' spacing={0.25}>
        {Array.from({length: cafeDish.spiciness}, (_, i) => (
          <Typography color='secondary' variant='body2' key={i}>
            <FaPepperHot />
          </Typography>
        ))}
      </Stack>
      <Typography>{`状态: ${cafeDish.status}`}</Typography>

      {cafeDish.categories.length > 0 && (
        <Stack direction='row' spacing={1}>
          {cafeDish.categories.map(category => (
            <Chip label={category} />
          ))}
        </Stack>
      )}

      <Typography>{`价格: $${cafeDish.price}`}</Typography>
      <Typography>{`折扣: ${cafeDish.discount * 100}%`}</Typography>
      {dishId === '' && (
        <Stack direction='row' spacing={1}>
          <Box sx={{flexGrow: 1}} />
          <WithLoading loading={processing}>
            <Button onClick={onDelete}>删除</Button>
          </WithLoading>

          <WithLoading loading={processing}>
            <Button onClick={onEditClick}>编辑</Button>
          </WithLoading>
        </Stack>
      )}
    </Stack>
  );
}

export default memo(ViewDish);
