import Bottom from 'pages/GuestPage/Menu/Bottom';
import Dishes from 'pages/GuestPage/Menu/Dishes';
import Top from 'pages/GuestPage/Menu/Top';
import {memo} from 'react';

function Menu() {
  return (
    <>
      <Top />
      <Dishes />
      <Bottom />
    </>
  );
}

export default memo(Menu);
