import {memo} from 'react';
import {
  GiAnchor,
  GiAnglerFish,
  GiChicken,
  GiCow,
  GiGinkgoLeaf,
  GiPig,
  GiSadCrab,
  GiShrimp,
  GiWaterBottle,
  GiWineGlass
} from 'react-icons/gi';
import {CafeDish} from 'utils/cafe-data';

import {Icon, Stack} from '@mui/material';

function DishBadges({cafeDish}: {cafeDish: CafeDish}) {
  const getBadge = (category: string) => {
    if (category === 'beef') return <GiCow />;
    if (category === 'pork') return <GiPig />;
    if (category === 'chicken') return <GiChicken />;
    if (category === 'seafood') return <GiAnchor />;
    if (category === 'shrimp') return <GiShrimp />;
    if (category === 'crab') return <GiSadCrab />;
    if (category === 'fish') return <GiAnglerFish />;
    if (category === 'drink') return <GiWaterBottle />;
    if (category === 'alcohol') return <GiWineGlass />;
    if (category === 'vegetable') return <GiGinkgoLeaf />;
    return <></>;
  };

  return (
    <Stack direction='row' spacing={1}>
      {cafeDish.categories.map((category, i) => (
        <Icon key={i}>{getBadge(category)}</Icon>
      ))}
    </Stack>
  );
}

export default memo(DishBadges);
