import WithLoading from 'pages/Common/WithLoading';
import {memo, useState} from 'react';
import {FaPepperHot} from 'react-icons/fa';
import {CafeDish} from 'utils/cafe-data';

import {Box, Paper, Stack, Typography} from '@mui/material';

const Price = memo(({cafeDish}: {cafeDish: CafeDish}) => {
  if (cafeDish.status === 'sold out') return <></>;
  return (
    <Box
      sx={{
        position: 'absolute',
        right: 0,
        bottom: 0,
        bgcolor: 'primary.main',
        px: 0.75,
        py: 0.25,
        opacity: 0.9,
        borderRadius: `6px 0 0 0`,
        overflow: 'hidden'
      }}>
      <Typography sx={{color: 'white'}} variant='body2'>
        {`$ ${cafeDish.price}`}
      </Typography>
    </Box>
  );
});

const Spiciness = memo(({cafeDish}: {cafeDish: CafeDish}) => {
  if (cafeDish.status === 'sold out') return <></>;
  if (cafeDish.spiciness === 0) return <></>;
  return (
    <Box
      sx={{
        position: 'absolute',
        right: 0,
        top: 0,
        bgcolor: 'secondary.main',
        px: 0.75,
        pt: 0.25,
        opacity: 0.9,
        borderRadius: `0 0 0 6px`,
        overflow: 'hidden'
      }}>
      <Stack direction='row' spacing={0.25}>
        {Array.from({length: cafeDish.spiciness}, (_, i) => (
          <Typography color='white' variant='body2' key={i}>
            <FaPepperHot />
          </Typography>
        ))}
      </Stack>
    </Box>
  );
});

const Status = memo(({cafeDish}: {cafeDish: CafeDish}) => {
  if (cafeDish.status === 'sold out') return <></>;
  if (!cafeDish.status.trim()) return <></>;
  const getText = () => {
    const t = cafeDish.status;
    if (t === 'popular') return '热销';
    if (t === 'sold out') return '售罄';
    if (t === 'special') return '推荐';
  };
  return (
    <Box
      sx={{
        position: 'absolute',
        left: 0,
        top: 0,
        bgcolor: 'secondary.main',
        px: 0.75,
        py: 0.25,
        borderRadius: `0 0 6px 0`,
        overflow: 'hidden'
      }}>
      <Typography sx={{color: 'white'}} variant='body2' fontWeight='bold'>
        {getText()}
      </Typography>
    </Box>
  );
});

function DishImage({cafeDish}: {cafeDish: CafeDish}) {
  const [loading, setLoading] = useState(true);
  const onImageLoad = () => {
    setLoading(false);
  };
  return (
    <WithLoading loading={loading}>
      <Paper
        sx={{
          display: 'flex',
          overflow: 'hidden',
          position: 'relative'
        }}>
        <Box
          component='img'
          src={cafeDish.image.image2D.url}
          alt={cafeDish.name.cn}
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
            filter:
              cafeDish.status === 'sold out' ? 'grayscale(100%)' : 'inherit'
          }}
          loading='lazy'
          onLoad={onImageLoad}
        />
        <Status cafeDish={cafeDish} />
        <Price cafeDish={cafeDish} />
        <Spiciness cafeDish={cafeDish} />
      </Paper>
    </WithLoading>
  );
}

export default memo(DishImage);
