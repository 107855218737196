import {memo, useEffect, useState} from 'react';

import {Box, Button, Container, Stack, Typography} from '@mui/material';
import {Link, useLocation} from 'react-router-dom';
import {CafeData, genCafe} from 'utils/cafe-data';
import {api} from 'utils/ui-client';
import {GiOlive} from 'react-icons/gi';

const routes = [
  {path: '/', label: '首页'},
  {path: '/admin', label: '管理员'},
  {path: '/guest', label: '顾客'}
];

function HomePage() {
  const [cafes, setCafes] = useState<CafeData[]>([]);
  const location = useLocation();

  const onAddCafe = async () => {
    const cafeData = genCafe();
    await api.dataClient.post('/cafe', cafeData);
    _getCafes();
  };

  const _getCafes = () => {
    api.dataClient.get('/cafes').then(({data}) => {
      setCafes(data);
    });
  };

  // 初始化
  useEffect(() => {
    _getCafes();
  }, []);

  return (
    <Container sx={{mt: 1}}>
      <Stack spacing={1}>
        <Stack direction='row' spacing={1}>
          <Stack justifyContent='center' sx={{color: 'primary.main'}}>
            <GiOlive size={24} />
          </Stack>
          <Button onClick={onAddCafe}>添加餐馆</Button>
          <Box sx={{flexGrow: 1}} />
          {routes.map(({path, label}) => (
            <Link to={path} key={path}>
              <Button disabled={location.pathname === path}>{label}</Button>
            </Link>
          ))}
        </Stack>

        <Stack spacing={1}>
          {cafes.map(cafe => (
            <Link key={cafe.cafeId} to={`/owner?cafeId=${cafe.cafeId}`}>
              <Button
                variant='outlined'
                fullWidth
                sx={{justifyContent: 'flex-start'}}>
                <Stack alignItems='flex-start'>
                  <Typography>{cafe.cafeId}</Typography>
                  <Typography variant='body2'>{cafe.name.cn}</Typography>
                  <Typography variant='body2'>{cafe.name.en}</Typography>
                </Stack>
              </Button>
            </Link>
          ))}
        </Stack>

        <Typography>TODO: 做法务页</Typography>
        <Typography>TODO: 要做联系页</Typography>
      </Stack>
    </Container>
  );
}

export default memo(HomePage);
