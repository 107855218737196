import {memo} from 'react';

import {AppBar, Container, Toolbar} from '@mui/material';

function TopNav({children}: {children: any}) {
  return (
    <>
      <AppBar>
        <Container>
          <Toolbar sx={{'&.MuiToolbar-root': {px: 0}}}>{children}</Toolbar>
        </Container>
      </AppBar>
      <Toolbar />
    </>
  );
}

export default memo(TopNav);
