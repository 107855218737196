import {useAtom} from 'jotai';
import {guestOrderAtom} from 'pages/GuestPage/data';
import {memo} from 'react';

import {Box, Divider, Stack, Typography} from '@mui/material';
import moment from 'moment';
import DishAction from 'pages/GuestPage/Order/Dishes/DishAction';

function DishOrders() {
  const [order] = useAtom(guestOrderAtom);
  const quantity = order.dishes.reduce((acc, dish) => {
    return acc + dish.quantity;
  }, 0);
  return (
    <Stack>
      <Stack direction='row' alignItems='flex-start'>
        <Typography
          fontWeight='bold'
          color='primary'
          gutterBottom>{`订单详情(${quantity}件)`}</Typography>
        <Box sx={{flexGrow: 1}} />
        <Typography>
          {moment(order.created).format('YYYY/MM/DD hh:mm a')}
        </Typography>
      </Stack>
      <Stack spacing={1}>
        {order.dishes.map(orderDish => (
          <Stack
            key={orderDish.dishId}
            spacing={1}
            sx={{bgcolor: 'info.main', p: 1, borderRadius: 1}}>
            <Stack direction='row' alignItems='center' spacing={1}>
              <Typography>{orderDish.name.cn}</Typography>
              <Box sx={{flexGrow: 1}}>
                <Divider sx={{borderStyle: 'dashed'}} />
              </Box>
              <Typography>{`$${orderDish.price}`}</Typography>
            </Stack>

            <DishAction orderDish={orderDish} />
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
}

export default memo(DishOrders);
