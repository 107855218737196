import {memo} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import HomePage from 'pages/HomePage';
import ErrorPage from 'pages/ErrorPage';
import AdminPage from 'pages/AdminPage';
import OwnerPage from 'pages/OwnerPage';
import GuestPage from 'pages/GuestPage';

function Router() {
  return (
    <BrowserRouter future={{v7_startTransition: true}}>
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/admin' element={<AdminPage />} />
        <Route path='/owner' element={<OwnerPage />} />
        <Route path='/guest' element={<GuestPage />} />
        <Route path='/*' element={<ErrorPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default memo(Router);
