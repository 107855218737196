import {useAtom} from 'jotai';
import {
  tableCapacityAtom,
  tableEditProcessingAtom,
  tableIdAtom,
  tableNameAtom,
  tableNoteAtom
} from 'pages/OwnerPage/TablesPanel/EditTable/data';
import {memo} from 'react';
import {CafeTable} from 'utils/cafe-data';

import {Box, Button, Stack, Typography} from '@mui/material';
import WithLoading from 'pages/Common/WithLoading';

function ViewTable({
  cafeTable,
  onDeleteTable
}: {
  cafeTable: CafeTable;
  onDeleteTable: (tableId: string) => void;
}) {
  const [tableId, setTableId] = useAtom(tableIdAtom);
  const [, setCapacity] = useAtom(tableCapacityAtom);
  const [, setName] = useAtom(tableNameAtom);
  const [, setNote] = useAtom(tableNoteAtom);
  const [processing] = useAtom(tableEditProcessingAtom);

  const onEditTable = () => {
    setTableId(cafeTable.tableId);
    setCapacity(cafeTable.capacity);
    setName(cafeTable.tableName);
    setNote(cafeTable.note);
  };

  const onDelete = () => {
    onDeleteTable(cafeTable.tableId);
  };

  return (
    <Stack spacing={0.5}>
      <Stack direction='row' spacing={1}>
        <Typography>桌名: </Typography>
        <Typography>{cafeTable.tableName}</Typography>
      </Stack>
      <Stack direction='row' spacing={1}>
        <Typography>人数: </Typography>
        <Typography>{cafeTable.capacity}</Typography>
      </Stack>
      <Stack direction='row' spacing={1}>
        <Typography>备注: </Typography>
        <Typography>{cafeTable.note}</Typography>
      </Stack>
      {tableId === '' && (
        <Stack direction='row' spacing={1}>
          <Box sx={{flexGrow: 1}} />
          <WithLoading loading={processing}>
            <Button onClick={onDelete}>删除</Button>
          </WithLoading>
          <WithLoading loading={processing}>
            <Button onClick={onEditTable}>编辑</Button>
          </WithLoading>
        </Stack>
      )}
    </Stack>
  );
}

export default memo(ViewTable);
