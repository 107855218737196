import {useAtom} from 'jotai';
import {cafeDishesAtom, cafeIdAtom} from 'pages/OwnerPage/data';
import EditDish from 'pages/OwnerPage/DishesPanel/EditDish';
import {
  dishEditProcessingAtom,
  dishIdAtom
} from 'pages/OwnerPage/DishesPanel/EditDish/data';
import ViewDish from 'pages/OwnerPage/DishesPanel/ViewDish';
import {memo, useEffect} from 'react';
import {CafeDish, genCafeDish} from 'utils/cafe-data';
import {api} from 'utils/ui-client';

import {Box, Button, Card, Stack} from '@mui/material';

function DishesPanel() {
  const [cafeId] = useAtom(cafeIdAtom);
  const [dishes, setDishes] = useAtom(cafeDishesAtom);
  const [editDishId] = useAtom(dishIdAtom);
  const [, setProcessing] = useAtom(dishEditProcessingAtom);

  const _getDishes = () => {
    api.dataClient
      .get(`/cafe_dishes/${cafeId}`)
      .then(({data}) => setDishes(data));
  };

  const onAddDish = async () => {
    setProcessing(true);
    const _dish: CafeDish = genCafeDish(cafeId);
    await api.dataClient.post('/cafe_dish', _dish);
    _getDishes();
    setProcessing(false);
  };

  const onSaveDish = async (_dish: CafeDish) => {
    setProcessing(true);
    await api.dataClient.put(`/cafe_dish/${_dish.dishId}`, _dish);
    _getDishes();
    setProcessing(false);
  };

  const onDeleteDish = async (dishId: string) => {
    setProcessing(true);
    await api.dataClient.delete(`/cafe_dish/${dishId}`);
    _getDishes();
    setProcessing(false);
  };

  // 初始化
  useEffect(() => {
    _getDishes();
    // eslint-disable-next-line
  }, []);

  return (
    <Stack spacing={1}>
      <Box>
        <Button onClick={onAddDish}>添加菜式</Button>
      </Box>
      {dishes.map(cafeDish => (
        <Card key={cafeDish.dishId} variant='outlined' sx={{p: 1}}>
          {editDishId === cafeDish.dishId ? (
            <EditDish
              key={cafeDish.dishId}
              onSaveDish={onSaveDish}
              onDeleteDish={onDeleteDish}
            />
          ) : (
            <ViewDish
              key={cafeDish.dishId}
              cafeDish={cafeDish}
              onDeleteDish={onDeleteDish}
            />
          )}
        </Card>
      ))}
    </Stack>
  );
}

export default memo(DishesPanel);
