import {useAtom} from 'jotai';
import {dishSampleProcessingAtom, dishSamplesAtom} from 'pages/AdminPage/data';
import DishSamples from 'pages/AdminPage/DishSamples';
import Search from 'pages/AdminPage/Search';
import TopNav from 'pages/Common/TopNav';
import {memo} from 'react';
import {Link} from 'react-router-dom';
import {genDishSample} from 'utils/cafe-data';
import {api} from 'utils/ui-client';

import {Box, Button, Stack} from '@mui/material';

function AdminPage() {
  const [, setSamples] = useAtom(dishSamplesAtom);
  const [, setProcessing] = useAtom(dishSampleProcessingAtom);

  const onAddDishSample = async () => {
    setProcessing(true);
    await api.dataClient.post('/dish_sample', genDishSample());
    await api.dataClient
      .get('/dish_samples')
      .then(({data}) => setSamples(data));
    setProcessing(false);
  };

  return (
    <>
      <TopNav>
        <Stack
          direction='row'
          alignItems='center'
          spacing={1}
          sx={{width: '100%'}}>
          <Search />
          <Box sx={{flexGrow: 1}} />
          <Button onClick={onAddDishSample}>加样本</Button>
          <Link to='/'>
            <Button>回首页</Button>
          </Link>
        </Stack>
      </TopNav>
      <DishSamples />
    </>
  );
}

export default memo(AdminPage);
