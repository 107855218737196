import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

const resources = {
  en: {
    translation: {
      语言: 'English',
      搜索菜单: 'Search Menu'
    }
  },
  cn: {
    translation: {
      语言: '中文',
      搜索菜单: '搜索菜单'
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'cn',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
