import {Box, Container, Grid, Skeleton, Stack} from '@mui/material';
import {memo} from 'react';

function Loader() {
  return (
    <Container>
      <Stack spacing={4} sx={{mt: 4}}>
        {Array.from({length: 4}, (_, i) => (
          <Box>
            <Grid container key={i} spacing={2}>
              <Grid item xs={6}>
                <Skeleton variant='rounded' height={72} />
              </Grid>

              <Grid item xs>
                <Stack sx={{width: '100%'}}>
                  <Skeleton height={24} width='60%' />
                  <Skeleton height={24} width='40%' />
                  <Skeleton />
                </Stack>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Stack>
    </Container>
  );
}

export default memo(Loader);
