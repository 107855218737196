import {useAtom} from 'jotai';
import {
  tableNoteAtom,
  tableOperationsAtom
} from 'pages/OwnerPage/TablesPanel/EditTable/data';
import {memo} from 'react';
import {useDebouncedCallback} from 'use-debounce';

import {Card, InputBase, Stack, Typography} from '@mui/material';

function EditNote() {
  const [tableNote, setNote] = useAtom(tableNoteAtom);
  const [, setOperations] = useAtom(tableOperationsAtom);
  const debounced = useDebouncedCallback(
    () => setOperations(_ => [..._, '保存备注']),
    1000
  );
  const onChange = (e: any) => {
    setNote(e.target.value);
    debounced();
  };

  return (
    <Stack>
      <Typography variant='body2' fontWeight='bold'>
        备注
      </Typography>
      <Card variant='outlined'>
        <InputBase
          value={tableNote}
          onChange={onChange}
          fullWidth
          placeholder='备注'
          sx={{bgcolor: 'action.hover', px: 1}}
        />
      </Card>
    </Stack>
  );
}

export default memo(EditNote);
