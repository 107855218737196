import {useAtom} from 'jotai';
import {guestOrderAtom} from 'pages/GuestPage/data';
import {memo} from 'react';

import {Box, Divider, Stack, Typography} from '@mui/material';

function DishPayment() {
  const [order] = useAtom(guestOrderAtom);

  const orderTotal = Number(
    order.dishes
      .reduce((acc, dish) => {
        return acc + dish.price * dish.quantity;
      }, 0)
      .toFixed(2)
  );

  const tax = Number((orderTotal * 0.09).toFixed(2));
  const payTotal = Number((orderTotal + tax).toFixed(2));
  return (
    <Stack>
      <Typography fontWeight='bold' color='primary' gutterBottom>
        点餐账单
      </Typography>
      <Stack spacing={2}>
        <Stack direction='row' alignItems='center' spacing={1}>
          <Typography>{`菜品金额`}</Typography>
          <Box sx={{flexGrow: 1}}>
            <Divider sx={{borderStyle: 'dashed'}} />
          </Box>
          <Typography>{`$${orderTotal}`}</Typography>
        </Stack>

        <Stack direction='row' alignItems='center' spacing={1}>
          <Typography>{`餐税`}</Typography>
          <Box sx={{flexGrow: 1}}>
            <Divider sx={{borderStyle: 'dashed'}} />
          </Box>
          <Typography>{`$${tax}`}</Typography>
        </Stack>

        <Stack direction='row' alignItems='center' spacing={1}>
          <Typography>{`应付金额`}</Typography>
          <Box sx={{flexGrow: 1}}>
            <Divider sx={{borderStyle: 'dashed'}} />
          </Box>
          <Typography color='primary' variant='h6'>
            {`$${payTotal}`}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default memo(DishPayment);
