import {useAtom} from 'jotai';
import BottomNav from 'pages/Common/BottomNav';
import {guestOrderAtom, guestTabAtom} from 'pages/GuestPage/data';
import {memo} from 'react';

import {Box, Button, Stack, Typography} from '@mui/material';

function Bottom() {
  const [order] = useAtom(guestOrderAtom);
  const [, setTab] = useAtom(guestTabAtom);

  const total = order.dishes
    .reduce((acc, dish) => {
      return acc + dish.price * dish.quantity;
    }, 0)
    .toFixed(2);

  const quantity = order.dishes.reduce((acc, dish) => {
    return acc + dish.quantity;
  }, 0);

  const qText = quantity === 0 ? '' : `(${quantity})`;
  return (
    <BottomNav>
      <Stack
        direction='row'
        alignItems='center'
        spacing={1}
        sx={{flexGrow: 1, bgcolor: 'white', borderRadius: 1, p: 1}}>
        <Typography color='primary' fontWeight='bold'>
          {`菜品金额: $${total}`}
        </Typography>

        <Box sx={{flexGrow: 1}} />

        <Button onClick={() => setTab('order')} disabled={quantity === 0}>
          {`下单${qText}`}
        </Button>
      </Stack>
    </BottomNav>
  );
}

export default memo(Bottom);
