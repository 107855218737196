import {atom} from 'jotai';
import {CafeDish, genOrder, OrderData} from 'utils/cafe-data';

export const cafeDishesAtom = atom<CafeDish[]>([]);
export const displayDishesAtom = atom<CafeDish[]>([]);
export const dishesLoadingAtom = atom(false);
export const guestOrderAtom = atom<OrderData>(genOrder());

// UI
type GuestTab = 'order' | 'menu';
export const guestTabAtom = atom<GuestTab>('menu');
