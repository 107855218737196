import {useAtom} from 'jotai';
import {
  tableCapacityAtom,
  tableOperationsAtom
} from 'pages/OwnerPage/TablesPanel/EditTable/data';
import {memo} from 'react';
import {useDebouncedCallback} from 'use-debounce';

import {Card, InputBase, Stack, Typography} from '@mui/material';

function EditCapacity() {
  const [tableCapacity, setCapacity] = useAtom(tableCapacityAtom);
  const [, setOperations] = useAtom(tableOperationsAtom);

  const debounced = useDebouncedCallback(
    () => setOperations(_ => [..._, '保存容纳']),
    1000
  );
  const onChange = (e: any) => {
    setCapacity(Number(e.target.value));
    debounced();
  };

  return (
    <Stack>
      <Typography variant='body2' fontWeight='bold'>
        容纳
      </Typography>
      <Card variant='outlined'>
        <InputBase
          value={Number(tableCapacity)}
          onChange={onChange}
          type='number'
          fullWidth
          placeholder='容纳'
          sx={{bgcolor: 'action.hover', px: 1}}
        />
      </Card>
    </Stack>
  );
}

export default memo(EditCapacity);
