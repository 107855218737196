import {useAtom} from 'jotai';
import {
  dishOperationsAtom,
  dishStatusAtom
} from 'pages/OwnerPage/DishesPanel/EditDish/data';
import {memo} from 'react';
import {useDebouncedCallback} from 'use-debounce';

import {MenuItem, Select, Stack, Typography} from '@mui/material';

function EditStatus() {
  const [dishStatus, setDishStatus] = useAtom(dishStatusAtom);
  const [, setOperations] = useAtom(dishOperationsAtom);

  const debounced = useDebouncedCallback(
    () => setOperations(_ => [..._, '保存状态']),
    1000
  );
  const onChange = (e: any) => {
    setDishStatus(e.target.value);
    debounced();
  };
  return (
    <Stack>
      <Typography variant='body2' fontWeight='bold'>
        状态
      </Typography>
      <Select
        labelId='dish-status'
        value={dishStatus}
        onChange={onChange}
        sx={{bgcolor: 'action.hover'}}>
        <MenuItem value=''>无</MenuItem>
        <MenuItem value='popular'>热销</MenuItem>
        <MenuItem value='special'>特价</MenuItem>
        <MenuItem value='sold out'>售罄</MenuItem>
      </Select>
    </Stack>
  );
}

export default memo(EditStatus);
