import {memo} from 'react';
import {FaCheck} from 'react-icons/fa';
import {DISH_CATEGORIES} from 'utils/cafe-data';

import {
  Autocomplete,
  Icon,
  MenuItem,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import {useAtom} from 'jotai';
import {
  dishCategoriesAtom,
  dishOperationsAtom
} from 'pages/OwnerPage/DishesPanel/EditDish/data';
import {useDebouncedCallback} from 'use-debounce';

function EditCategories() {
  const [categories, setCategories] = useAtom(dishCategoriesAtom);
  const [, setOperations] = useAtom(dishOperationsAtom);

  const debounced = useDebouncedCallback(
    () => setOperations(_ => [..._, '保存菜名']),
    1000
  );
  const onChange = (e: any, _categories: any) => {
    setCategories(_categories);
    debounced();
  };
  return (
    <Stack>
      <Typography variant='body2' fontWeight='bold'>
        分类
      </Typography>
      <Autocomplete
        multiple
        options={DISH_CATEGORIES}
        value={categories}
        getOptionLabel={option => option}
        disableCloseOnSelect
        onChange={onChange}
        renderInput={params => (
          <TextField
            {...params}
            variant='outlined'
            sx={{bgcolor: 'action.hover'}}
          />
        )}
        renderOption={(props, option, {selected}) => (
          <MenuItem
            {...props}
            key={option}
            value={option}
            sx={{justifyContent: 'space-between'}}>
            <Stack direction='row' spacing={1} alignItems='center'>
              <Typography variant='body2'>{option}</Typography>
              {selected && (
                <Icon color='primary'>
                  <FaCheck />
                </Icon>
              )}
            </Stack>
          </MenuItem>
        )}
      />
    </Stack>
  );
}

export default memo(EditCategories);
