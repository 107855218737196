import {useAtom} from 'jotai';
import {
  dishOperationsAtom,
  dishPriceAtom
} from 'pages/OwnerPage/DishesPanel/EditDish/data';
import {memo} from 'react';
import {useDebouncedCallback} from 'use-debounce';

import {Card, InputBase, Stack, Typography} from '@mui/material';

function EditPrice() {
  const [dishPrice, setDishPrice] = useAtom(dishPriceAtom);
  const [, setOperations] = useAtom(dishOperationsAtom);
  const debounced = useDebouncedCallback(
    () => setOperations(_ => [..._, '保存价格']),
    1000
  );
  const onChange = (e: any) => {
    setDishPrice(Number(e.target.value));
    debounced();
  };

  return (
    <Stack>
      <Typography variant='body2' fontWeight='bold'>
        价格
      </Typography>
      <Card variant='outlined'>
        <InputBase
          value={dishPrice}
          onChange={onChange}
          fullWidth
          type='number'
          placeholder='价格'
          sx={{bgcolor: 'action.hover', px: 1}}
        />
      </Card>
    </Stack>
  );
}

export default memo(EditPrice);
