import axios from 'axios';
import Status from 'http-status';

class API {
  user = {userId: ''};
  base =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:5000/api'
      : '/api';
  BAD_REQUEST = Status.BAD_REQUEST; // 400
  UNAUTHORIZED = Status.UNAUTHORIZED; // 401
  FORBIDDEN = Status.FORBIDDEN; // 403
  NOT_FOUND = Status.NOT_FOUND; // 404

  private genHeaders(type: string) {
    const headers: any = {'Content-Type': type};
    return headers;
  }

  public get fileClient() {
    return axios.create({
      baseURL: this.base,
      headers: this.genHeaders('multipart/form-data'),
      maxBodyLength: Infinity,
      maxContentLength: Infinity
    });
  }

  public get dataClient() {
    return axios.create({
      baseURL: this.base,
      headers: this.genHeaders('application/json')
    });
  }
  public get formClient() {
    return axios.create({
      baseURL: this.base,
      headers: this.genHeaders('application/x-www-form-urlencoded')
    });
  }
}
export const api = new API();
