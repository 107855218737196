import {memo} from 'react';

import {AppBar, Container, Toolbar} from '@mui/material';

function BottomNav({children}: {children: any}) {
  return (
    <>
      <AppBar sx={{top: 'auto', bottom: 0}}>
        <Container>
          <Toolbar sx={{'&.MuiToolbar-root': {px: 0}}}>{children}</Toolbar>
        </Container>
      </AppBar>
      <Toolbar />
    </>
  );
}

export default memo(BottomNav);
