import {useAtom} from 'jotai';
import {
  cafeHoursAtom,
  cafeOperationsAtom
} from 'pages/OwnerPage/CafePanel/data';
import {memo} from 'react';
import {useDebouncedCallback} from 'use-debounce';

import {Card, InputBase} from '@mui/material';

function CafeHours() {
  const [cafeHours, setCafeHours] = useAtom(cafeHoursAtom);
  const [, setOperations] = useAtom(cafeOperationsAtom);
  const debounced = useDebouncedCallback(
    () => setOperations(_ => [..._, '保存餐厅营业时间']),
    1000
  );
  const onChange = (e: any) => {
    setCafeHours(_ => e.target.value);
    debounced();
  };
  return (
    <Card variant='outlined'>
      <InputBase
        value={cafeHours}
        onChange={onChange}
        fullWidth
        multiline
        minRows={3}
        placeholder='营业时间'
        sx={{bgcolor: 'action.hover', px: 1, py: 0.5}}
      />
    </Card>
  );
}

export default memo(CafeHours);
