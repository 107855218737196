import {useAtom} from 'jotai';
import {
  cafeIntroAtom,
  cafeOperationsAtom
} from 'pages/OwnerPage/CafePanel/data';
import {memo} from 'react';
import {useDebouncedCallback} from 'use-debounce';

import {Card, InputBase} from '@mui/material';

function CafeIntro() {
  const [cafeIntro, setCafeIntro] = useAtom(cafeIntroAtom);
  const [, setOperations] = useAtom(cafeOperationsAtom);
  const debounced = useDebouncedCallback(
    () => setOperations(_ => [..._, '保存餐厅介绍']),
    1000
  );
  const onChange = (e: any) => {
    setCafeIntro(_ => e.target.value);
    debounced();
  };
  return (
    <Card variant='outlined'>
      <InputBase
        value={cafeIntro}
        onChange={onChange}
        fullWidth
        multiline
        minRows={3}
        placeholder='餐馆介绍'
        sx={{bgcolor: 'action.hover', px: 1, py: 0.5}}
      />
    </Card>
  );
}

export default memo(CafeIntro);
