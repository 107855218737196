import {createTheme} from '@mui/material/styles';

const COLOR = {
  red: '#be443b',
  green: '#3e816e',
  gold: '#f6d39c',
  white: '#ffffff',
  black: '#4b4b4b'
};

export const theme = createTheme({
  palette: {
    primary: {main: COLOR.green},
    secondary: {main: COLOR.red},
    info: {main: COLOR.gold}
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 360,
      md: 680,
      lg: 960,
      xl: 1200
    }
  },
  components: {
    MuiAppBar: {
      defaultProps: {position: 'fixed'}
    },
    MuiContainer: {
      defaultProps: {maxWidth: 'md'}
    },
    MuiButton: {
      defaultProps: {
        size: 'small',
        color: 'primary',
        variant: 'contained',
        disableElevation: true
      },
      styleOverrides: {
        contained: {
          color: COLOR.gold,
          fontWeight: 'bold'
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiSelect: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small'
      }
    },
    MuiButtonGroup: {
      defaultProps: {
        disableElevation: true
      }
    }
  },
  typography: {
    h1: {lineHeight: 'initial'},
    h2: {lineHeight: 'initial'},
    h3: {lineHeight: 'initial'},
    h4: {lineHeight: 'initial', fontWeight: 'bold'},
    h5: {lineHeight: 'initial', fontWeight: 'bold'},
    h6: {lineHeight: 'initial', fontWeight: 'bold'},
    button: {textTransform: 'inherit'}
  }
});

export function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
}
