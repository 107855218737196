import {useAtom} from 'jotai';
import {
  dishImageAtom,
  sampleOperationsAtom
} from 'pages/AdminPage/DishSamples/EditSample/data';
import {memo, useState} from 'react';
import {DISH_IMG_H, DISH_IMG_W} from 'utils/cafe-data';
import {api} from 'utils/ui-client';

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography
} from '@mui/material';

function EditImage3D() {
  const [dishImage, setDishImage] = useAtom(dishImageAtom);
  const [, setSampleOperations] = useAtom(sampleOperationsAtom);
  const [open, setOpen] = useState(false);
  const [image3D, setImage3D] = useState<string>('');

  const onSetupDialog = (e: any) => {
    const files = e.target.files;
    const file = files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setImage3D(reader.result as string);
      setOpen(true);
    };
    reader.readAsDataURL(file);
  };

  const upload3DImage = async () => {
    if (!image3D) return;
    if (dishImage.image3D.path) {
      await api.dataClient.delete(`/file/delete/${dishImage.image3D.path}`);
    }
    const formData = new FormData();
    formData.append('folder', 'dish-samples');
    formData.append('files', await (await fetch(image3D)).blob());
    api.fileClient.post('/file/upload', formData).then(({data}) => {
      setDishImage(_ => ({..._, image3D: data[0]}));
      setSampleOperations(_ => [..._, '上传3D图片']);
    });
    setOpen(false);
  };

  return (
    <Stack>
      <Typography variant='body2' fontWeight='bold'>
        3D图片
      </Typography>
      <Stack spacing={1}>
        {dishImage.image3D.url && (
          <Box
            component='img'
            loading='lazy'
            sx={{height: DISH_IMG_H, width: DISH_IMG_W, borderRadius: 1}}
            src={dishImage.image3D.url}
            alt=''
          />
        )}
        <input
          type='file'
          accept='image/*'
          onChange={onSetupDialog}
          style={{display: 'none'}}
          id='dish-sample-3d-upload'
        />
        <label htmlFor='dish-sample-3d-upload'>
          <Button component='span'>上传3D图片</Button>
        </label>
      </Stack>

      {image3D && typeof image3D === 'string' && (
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>上传GIF图片</DialogTitle>
          <DialogContent>
            <Stack spacing={2}>
              <Box
                component='img'
                loading='lazy'
                sx={{borderRadius: 1}}
                src={image3D}
              />

              <Stack direction='row' spacing={1}>
                <Box sx={{flexGrow: 1}} />
                <Button variant='outlined' onClick={() => setOpen(false)}>
                  取消
                </Button>
                <Button onClick={upload3DImage}>确认</Button>
              </Stack>
            </Stack>
          </DialogContent>
        </Dialog>
      )}
    </Stack>
  );
}

export default memo(EditImage3D);
