import {useAtom} from 'jotai';
import {cafeNameAtom, cafeOperationsAtom} from 'pages/OwnerPage/CafePanel/data';
import {memo} from 'react';
import {useDebouncedCallback} from 'use-debounce';

import {Card, InputBase} from '@mui/material';

function CafeName() {
  const [cafeName, setCafeName] = useAtom(cafeNameAtom);
  const [, setOperations] = useAtom(cafeOperationsAtom);
  const debounced = useDebouncedCallback(
    () => setOperations(_ => [..._, '保存餐厅名']),
    1000
  );
  const onCnChange = (e: any) => {
    setCafeName(_ => ({..._, cn: e.target.value}));
    debounced();
  };

  const onEnChange = (e: any) => {
    setCafeName(_ => ({..._, en: e.target.value}));
    debounced();
  };

  return (
    <>
      <Card variant='outlined'>
        <InputBase
          value={cafeName.cn}
          onChange={onCnChange}
          fullWidth
          placeholder='餐馆中文名'
          sx={{bgcolor: 'action.hover', px: 1, py: 0.5}}
        />
      </Card>

      <Card variant='outlined'>
        <InputBase
          value={cafeName.en}
          onChange={onEnChange}
          fullWidth
          placeholder='Cafe English Name'
          sx={{bgcolor: 'action.hover', px: 1, py: 0.5}}
        />
      </Card>
    </>
  );
}

export default memo(CafeName);
