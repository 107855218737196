import {useAtom} from 'jotai';
import {cafeIdAtom, cafeTablesAtom} from 'pages/OwnerPage/data';
import EditTable from 'pages/OwnerPage/TablesPanel/EditTable';
import {
  tableEditProcessingAtom,
  tableIdAtom
} from 'pages/OwnerPage/TablesPanel/EditTable/data';
import ViewTable from 'pages/OwnerPage/TablesPanel/ViewTable';
import {memo, useEffect} from 'react';
import {CafeTable, genCafeTable} from 'utils/cafe-data';
import {api} from 'utils/ui-client';

import {Box, Button, Card, Stack} from '@mui/material';

function TablesPanel() {
  const [cafeId] = useAtom(cafeIdAtom);
  const [tables, setTables] = useAtom(cafeTablesAtom);
  const [tableId] = useAtom(tableIdAtom);
  const [, setProcessing] = useAtom(tableEditProcessingAtom);

  const _getTables = () => {
    api.dataClient.get(`/cafe_tables/${cafeId}`).then(({data}) => {
      setTables(data);
    });
  };

  const onAddTable = async () => {
    setProcessing(true);
    const _table: CafeTable = genCafeTable(cafeId);
    await api.dataClient.post('/cafe_table', _table);
    _getTables();
    setProcessing(false);
  };

  const onSaveTable = async (_table: CafeTable) => {
    setProcessing(true);
    await api.dataClient.put(`/cafe_table/${_table.tableId}`, _table);
    _getTables();
    setProcessing(false);
  };

  const onDeleteTable = async (_tableId: string) => {
    setProcessing(true);
    await api.dataClient.delete(`/cafe_table/${_tableId}`);
    _getTables();
    setProcessing(false);
  };

  // 初始化
  useEffect(() => {
    _getTables();
    // eslint-disable-next-line
  }, []);

  return (
    <Stack spacing={1}>
      <Box>
        <Button onClick={onAddTable}>添加餐桌</Button>
      </Box>

      {tables.map(cafeTable => (
        <Card key={cafeTable.tableId} variant='outlined' sx={{p: 1}}>
          {tableId === cafeTable.tableId ? (
            <EditTable
              key={cafeTable.tableId}
              onSaveTable={onSaveTable}
              onDeleteTable={onDeleteTable}
            />
          ) : (
            <ViewTable
              key={cafeTable.tableId}
              cafeTable={cafeTable}
              onDeleteTable={onDeleteTable}
            />
          )}
        </Card>
      ))}
    </Stack>
  );
}

export default memo(TablesPanel);
