import {useAtom} from 'jotai';
import {guestOrderAtom, guestTabAtom} from 'pages/GuestPage/data';
import {memo} from 'react';
import {FaChevronLeft} from 'react-icons/fa';

import {Box, Button, Stack, Typography} from '@mui/material';

function Top() {
  const [, setTab] = useAtom(guestTabAtom);
  const [order] = useAtom(guestOrderAtom);
  return (
    <Stack direction='row' alignItems='center' spacing={1} sx={{flexGrow: 1}}>
      <Button
        onClick={() => setTab('menu')}
        sx={{
          bgcolor: 'primary.main',
          color: 'info.main',
          minWidth: 'auto',
          p: 1
        }}>
        <FaChevronLeft />
      </Button>
      <Box sx={{flexGrow: 1}} />
      <Stack
        direction='row'
        divider={<Typography color='primary'>-</Typography>}
        spacing={0.5}>
        <Typography color='primary' fontWeight='bold'>
          {order.cafeName}
        </Typography>
        <Typography color='primary'>{order.tableId}</Typography>
        <Typography color='primary'> {`#${order.queueId}`}</Typography>
      </Stack>
    </Stack>
  );
}

export default memo(Top);
