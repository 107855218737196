import {useAtom} from 'jotai';
import {cafeIdAtom} from 'pages/OwnerPage/data';
import {
  dishCategoriesAtom,
  dishCounterAtom,
  dishDiscountAtom,
  dishEditProcessingAtom,
  dishIdAtom,
  dishImageAtom,
  dishMenuIdAtom,
  dishNameAtom,
  dishOperationsAtom,
  dishPriceAtom,
  dishSpicinessAtom,
  dishStatusAtom
} from 'pages/OwnerPage/DishesPanel/EditDish/data';
import EditCategories from 'pages/OwnerPage/DishesPanel/EditDish/EditCategories';
import EditDiscount from 'pages/OwnerPage/DishesPanel/EditDish/EditDiscount';
import EditImage2D from 'pages/OwnerPage/DishesPanel/EditDish/EditImage2D';
import EditImage3D from 'pages/OwnerPage/DishesPanel/EditDish/EditImage3D';
import EditName from 'pages/OwnerPage/DishesPanel/EditDish/EditName';
import EditPrice from 'pages/OwnerPage/DishesPanel/EditDish/EditPrice';
import EditSpiciness from 'pages/OwnerPage/DishesPanel/EditDish/EditSpiciness';
import EditStatus from 'pages/OwnerPage/DishesPanel/EditDish/EditStatus';
import {memo, useEffect} from 'react';
import {CafeDish} from 'utils/cafe-data';

import {Box, Button, Stack} from '@mui/material';
import WithLoading from 'pages/Common/WithLoading';

function EditDish({
  onSaveDish,
  onDeleteDish
}: {
  onSaveDish: (dish: CafeDish) => void;
  onDeleteDish: (dishId: string) => void;
}) {
  const [cafeId] = useAtom(cafeIdAtom);
  const [dishId, setDishId] = useAtom(dishIdAtom);
  const [dishCounter] = useAtom(dishCounterAtom);
  const [dishMenuId] = useAtom(dishMenuIdAtom);
  const [dishName, setDishName] = useAtom(dishNameAtom);
  const [dishCategories, setDishCategories] = useAtom(dishCategoriesAtom);
  const [dishImage, setDishImage] = useAtom(dishImageAtom);
  const [dishPrice, setDishPrice] = useAtom(dishPriceAtom);
  const [dishStatus, setDishStatus] = useAtom(dishStatusAtom);
  const [dishDiscount, setDishDiscount] = useAtom(dishDiscountAtom);
  const [dishSpiciness, setDishSpiciness] = useAtom(dishSpicinessAtom);
  const [operations, setDishOperations] = useAtom(dishOperationsAtom);
  const [processing] = useAtom(dishEditProcessingAtom);

  const _getCafeDish = (): CafeDish => {
    return {
      cafeId,
      dishId,
      dishMenuId: dishMenuId,
      counter: dishCounter,
      name: dishName,
      categories: dishCategories,
      image: dishImage,
      discount: dishDiscount,
      price: dishPrice,
      status: dishStatus,
      spiciness: dishSpiciness
    };
  };

  const onSave = async () => {
    await onSaveDish(_getCafeDish());
    // Exit
    setDishId('');
    setDishName({cn: '', en: ''});
    setDishCategories([]);
    setDishImage({
      image2D: {url: '', path: '', type: '', bytes: 0},
      image3D: {url: '', path: '', type: '', bytes: 0}
    });
    setDishDiscount(0);
    setDishPrice(0);
    setDishStatus('');
    setDishSpiciness(0);
  };

  const onDelete = () => {
    onDeleteDish(dishId);
  };

  const _onDirtySave = async () => {
    await onSaveDish(_getCafeDish());
    setDishOperations(_ => _.slice(1));
  };

  useEffect(() => {
    if (operations.length === 0) return;
    _onDirtySave();
    // eslint-disable-next-line
  }, [operations]);

  return (
    <Stack spacing={3}>
      <EditName />
      <EditImage2D />
      <EditImage3D />
      <EditSpiciness />
      <EditStatus />
      <EditCategories />
      <EditPrice />
      <EditDiscount />
      <Stack direction='row' spacing={1}>
        <Box sx={{flexGrow: 1}} />
        <WithLoading loading={processing}>
          <Button onClick={onDelete}>删除</Button>
        </WithLoading>

        <WithLoading loading={processing}>
          <Button onClick={onSave}>保存</Button>
        </WithLoading>
      </Stack>
    </Stack>
  );
}

export default memo(EditDish);
