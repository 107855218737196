import Bottom from 'pages/GuestPage/Order/Bottom';
import Dishes from 'pages/GuestPage/Order/Dishes';
import Top from 'pages/GuestPage/Order/Top';
import {memo} from 'react';

import {Container} from '@mui/material';

function Order() {
  return (
    <Container sx={{mt: 2}}>
      <Top />
      <Dishes />
      <Bottom />
    </Container>
  );
}

export default memo(Order);
