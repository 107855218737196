import {useAtom} from 'jotai';
import {
  dishDiscountAtom,
  dishOperationsAtom
} from 'pages/OwnerPage/DishesPanel/EditDish/data';
import {memo} from 'react';
import {useDebouncedCallback} from 'use-debounce';

import {Card, InputBase, Stack, Typography} from '@mui/material';

function EditDiscount() {
  const [dishDiscount, setDishDiscount] = useAtom(dishDiscountAtom);
  const [, setOperations] = useAtom(dishOperationsAtom);

  const debounced = useDebouncedCallback(
    () => setOperations(_ => [..._, '保存折扣']),
    1000
  );
  const onChange = (e: any) => {
    setDishDiscount(Number(e.target.value));
    debounced();
  };

  return (
    <Stack>
      <Typography variant='body2' fontWeight='bold'>
        折扣
      </Typography>
      <Card variant='outlined'>
        <InputBase
          value={dishDiscount}
          onChange={onChange}
          fullWidth
          type='number'
          placeholder='折扣'
          sx={{bgcolor: 'action.hover', px: 1}}
        />
      </Card>
    </Stack>
  );
}

export default memo(EditDiscount);
