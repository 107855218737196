import ShortUniqueId from 'short-unique-id';

// Predefined options
export const DISH_IMG_H = 111;
export const DISH_IMG_W = 167;
export const DISH_CATEGORIES: string[] = [
  'beef',
  'pork',
  'chicken',
  'seafood',
  'shrimp',
  'crab',
  'fish',
  'drink',
  'alcohol',
  'vegetable'
];

export type DishStatus = 'popular' | 'special' | 'sold out' | '';
export const DISH_STATUS: DishStatus[] = ['popular', 'special', 'sold out', ''];
export interface ImageData {
  url: string;
  path: string;
  type: string;
  bytes: number;
}
// Cafe
export interface CafeTable {
  cafeId: string;
  tableId: string;
  tableName: string;
  capacity: number;
  note: string;
}

export interface CafeName {
  cn: string;
  en: string;
}

export interface CafeData {
  cafeId: string;
  name: CafeName;
  phone: string;
  address: string;
  hours: string;
  intro: string;
}

// Dish
export interface DishImage {
  image2D: ImageData;
  image3D: ImageData;
}

export interface DishName {
  en: string;
  cn: string;
}
export interface DishCounter {
  totalSold: number;
  monthSold: number;
  weekSold: number;
  daySold: number;
}

export interface CafeDish {
  cafeId: string;
  dishId: string;
  dishMenuId: number;
  categories: string[];
  name: DishName;
  image: DishImage;
  counter: DishCounter;
  spiciness: number;
  price: number;
  status: DishStatus;
  discount: number;
}

// Order
export interface OrderDish {
  dishId: string;
  name: DishName;
  quantity: number;
  price: number;
}

interface OrderGuest {
  note: string;
  phone: string;
}

export interface OrderData {
  orderId: string;
  cafeId: string;
  cafeName: string;
  queueId: number;
  tableId: string;
  status: 'open' | 'closed';
  dishes: OrderDish[];
  totalPrice: number;
  guest: OrderGuest;
  created: number;
  finished: number;
}

// Sample
export interface DishSample {
  sampleId: string;
  name: DishName;
  image: DishImage;
}

// Generator
export const genCafeId = (): string => {
  const uuid = new ShortUniqueId({length: 8});
  return `cafe-${uuid()}`;
};

export const genDishId = (): string => {
  const uuid = new ShortUniqueId({length: 8});
  return `dish-${uuid()}`;
};

export const genDishSampleId = (): string => {
  const uuid = new ShortUniqueId({length: 8});
  return `dish-sample-${uuid()}`;
};

export const genTableId = (): string => {
  const uuid = new ShortUniqueId({length: 8});
  return `table-${uuid()}`;
};

export const genOrderId = (): string => {
  const uuid = new ShortUniqueId({length: 8});
  return `order-${uuid()}`;
};

export const getRandom = (items: Array<any>) => {
  const len = items.length;
  const idx = Math.floor(Math.random() * len);
  return items[idx];
};

export const genCafe = (): CafeData => {
  return {
    cafeId: genCafeId(),
    name: {
      en: 'Go Bigger',
      cn: '高比格'
    },
    intro: '',
    address: '',
    phone: '',
    hours: ''
  };
};

export const genCafeTable = (cafeId: string): CafeTable => {
  return {
    cafeId,
    tableId: genTableId(),
    tableName: 'A1',
    capacity: 4,
    note: ''
  };
};
export const genDish = (): CafeDish => {
  return {
    dishId: genDishId(),
    cafeId: genCafeId(),
    dishMenuId: 0,
    categories: [getRandom(DISH_CATEGORIES)],
    name: getRandom([
      {cn: '宫保鸡丁', en: 'Kung Pao Chicken'},
      {cn: '麻婆豆腐', en: 'Mapo Tofu'},
      {cn: '水煮牛肉', en: 'Boiled Beef in Sichuan Style'},
      {cn: '红烧肉', en: 'Braised Pork Belly'},
      {cn: '糖醋里脊', en: 'Sweet and Sour Pork'},
      {cn: '鱼香肉丝', en: 'Fish-Flavored Shredded Pork'},
      {cn: '辣子鸡', en: 'Spicy Diced Chicken'},
      {cn: '酸菜鱼', en: 'Sour and Spicy Fish'},
      {cn: '炒面', en: 'Stir-Fried Noodles'},
      {cn: '北京烤鸭', en: 'Peking Duck'},
      {cn: '回锅肉', en: 'Twice-Cooked Pork'},
      {cn: '炒饭', en: 'Fried Rice'},
      {cn: '手撕包菜', en: 'Hand-Pulled Cabbage'},
      {cn: '清蒸鱼', en: 'Steamed Fish'},
      {cn: '烧白菜', en: 'Braised Chinese Cabbage'},
      {cn: '锅包肉', en: 'Sweet and Sour Pork, Northeast Style'},
      {cn: '花甲粉丝', en: 'Clams with Vermicelli'},
      {cn: '糖醋排骨', en: 'Sweet and Sour Spare Ribs'},
      {cn: '青椒肉丝', en: 'Stir-Fried Pork with Green Peppers'},
      {cn: '拔丝地瓜', en: 'Candied Sweet Potatoes'},
      {cn: '清炒虾仁', en: 'Sauteed Prawns'},
      {cn: '麻辣香锅', en: 'Spicy Hot Pot'},
      {cn: '椒盐排骨', en: 'Salt and Pepper Ribs'},
      {cn: '香煎豆腐', en: 'Pan-Fried Tofu'},
      {cn: '酸辣汤', en: 'Hot and Sour Soup'},
      {cn: '烧鸡', en: 'Roast Chicken'},
      {cn: '鸡丝炒面', en: 'Chicken Chow Mein'},
      {cn: '咖喱牛腩', en: 'Curry Beef Brisket'},
      {cn: '羊肉串', en: 'Mutton Kebab'},
      {cn: '豉汁蒸排骨', en: 'Steamed Spare Ribs with Black Bean Sauce'},
      {cn: '炒河粉', en: 'Stir Fried Flat Rice Noodle'},
      {cn: '椒盐鲜鱿', en: 'Salt and Pepper Squid'},
      {cn: '宫保虾仁', en: 'Kung Pao Prawns'},
      {cn: '糖醋鱼', en: 'Sweet and Sour Fish'},
      {cn: '蒜蓉生蚝', en: 'Garlic Steamed Oysters'},
      {cn: '蒜泥白肉', en: 'Pork Belly with Garlic Sauce'},
      {cn: '炸酱面', en: 'Zhajiang Noodles'},
      {cn: '梅菜扣肉', en: 'Braised Pork with Preserved Vegetables'},
      {cn: '红烧排骨', en: 'Braised Spare Ribs'},
      {cn: '豆芽炒肉丝', en: 'Stir Fried Pork with Bean Sprouts'},
      {cn: '炒冬瓜', en: 'Stir-fried Winter Melon'},
      {cn: '海鲜炒饭', en: 'Seafood Fried Rice'},
      {cn: '虾仁炒蛋', en: 'Scrambled Eggs with Shrimp'},
      {cn: '清蒸大虾', en: 'Steamed Shrimp'},
      {cn: '鱼香茄子', en: 'Fish-Flavored Eggplant'},
      {cn: '蚝油牛肉', en: 'Beef in Oyster Sauce'},
      {cn: '凉拌黄瓜', en: 'Chinese Cucumber Salad'},
      {cn: '豆瓣酱炒肉', en: 'Stir-Fried Pork in Bean Paste'},
      {cn: '芝麻鸡', en: 'Sesame Chicken'},
      {cn: '咸鱼炒饭', en: 'Salted Fish Fried Rice'},
      {cn: '油淋鸡', en: 'Oil Dripped Chicken'},
      {cn: '烤乳猪', en: 'Roasted Suckling Pig'},
      {cn: '醋溜白菜', en: 'Stir-Fried Chinese Cabbage in Vinegar'},
      {cn: '葱油饼', en: 'Scallion Pancake'},
      {cn: '豆芽炒面', en: 'Bean Sprouts Stir-Fry Noodles'},
      {cn: '香菇滑鸡', en: 'Chicken with Mushrooms'},
      {cn: '沙茶牛肉', en: 'Satay Beef'},
      {cn: '香煎饺子', en: 'Pan-Fried Dumplings'},
      {cn: '九转大肠', en: 'Sweet and Sour Crispy Intestines'},
      {cn: '毛血旺', en: 'Spicy Hotpot with various ingredients'},
      // 土匪湘菜帮
      {cn: '武侯泡椒鸡胗', en: 'Chicken Intestine with pickled peppers'},
      {cn: '川味箩卜干腊肉', en: 'Dried Turnip with Smoked Ham'},
      {cn: '酸菜鸭血', en: 'Sour Cabbage with Duck Blood'},
      {cn: '马帮辣炒臭豆腐', en: 'Spicy Stir-Fried Stinky Tofu'},
      {
        cn: '匪王酸豆角鸡胗',
        en: 'Ground Pork Stir Fried w/ Pickled Green Beans'
      },
      {cn: '马帮烟笋腊肉', en: 'Cured bamboo shoot wHam'},
      {
        cn: '土匪油渣子辣炒菜花',
        en: 'Organic Cauliflower & Smoked Ham w/ 4 Season Chilies'
      },
      {cn: '五更肠旺臭豆腐', en: 'Intestinal Stinky Tofu'},
      {cn: '五更肠旺', en: 'Pork Intestines Fire Pot'},
      {cn: '船拐子鸭头', en: 'Sauteed Duck Head with Chills'},
      {cn: '土匪麻酱凉面', en: 'Sichuan Cold Sesame Noodles (Vegetarian)'},
      {cn: '铁板王家豆腐', en: "Chef Wang's Sizzling Tofu with Basil"},
      {
        cn: '铁板鱼香茄子豆腐',
        en: 'Sizzling Eggplant and Tofu with Garlic Sause'
      },
      {cn: '铁板王家鸡', en: "Chef Wang's Sizzling Chicken with Basil"},
      {
        cn: '土匪烤鱼素码',
        en: 'Vegetable Mixed with Tofu Skin Pop Fried with Cumin Spices'
      },
      {cn: '葱爆牛', en: 'Beef and Green Onion Stir Fry'},
      {cn: '孜然牛', en: 'Fried Lamb with Cumin'},
      {cn: '盐酥鸡', en: 'Salt crispy chicken'},
      {cn: '蒙古牛', en: 'Mongolian Beef'},
      {cn: '陈皮鸡', en: 'Citrusy Chicken'},
      {cn: '咖喱鸡', en: 'Sliced Chicken Breast Sauteed with Curry'},
      {cn: '宫保虾球', en: 'Kung-Pao Shrimp'},
      {cn: '西兰花牛肉', en: 'Broccoli beef'},
      {cn: '左宗脆皮鱼', en: 'Pineapple Crispy Fish'},
      {cn: '烧汁面包虾', en: 'Breaded Shrimp with Braised Sauce'},
      {cn: '金陵盐水鸭', en: 'Nanjing Salty duck'},
      {cn: '本帮五香熏鱼', en: 'Shanghai-style Five-Spice Smoked Fish'},
      {cn: '江浙滬糖醋排骨', en: 'Sweet and Sour Pork Ribs'},
      {cn: '干烹鸡中翅', en: 'Stir-fried chicken wings with house made sauce'},
      {cn: '滑蛋虾仁/嫩牛', en: 'Scrambled Egg with Shrimp or Beef'},
      {cn: '少校梅菜扣肉', en: 'Braised Pork with Preserved Vegetable'},
      {cn: '金陵手打魚丸絲瓜', en: ' Hand fish balls with loofah'},
      {cn: '西兰花虾仁蒸蛋', en: 'Broccoli and shrimp steam with egg'},
      {cn: '干煸四季豆', en: 'Garlic Green bean'},
      {cn: '干锅莲藕', en: 'Lotus Roots with 4 Season Chilies (Vegetarian)'},
      {cn: '豆豉鲮鱼', en: 'Fried dace with salted black beans w/vetgetable'},
      {cn: '上汤大豆苗', en: 'Bean Sprouts in Soup with Ham'},
      {
        cn: '干锅土豆片',
        en: 'Fried Potato Slices w/ Diced Chilies (Vegetarian)'
      },
      {cn: '荷塘小炒', en: 'Stir-fry Lotus w/Vegetable'},
      {cn: '虎椒皮蛋', en: 'Grilled Pepper w/ Thousand Years Old Egg (Veg.)'},
      {cn: '麻婆豆腐', en: 'Ma Po Tofu with Ground Pork'},
      {
        cn: '干锅豆腐王',
        en: 'Mixed tofu Sauteed in Huanan Chili Sauce Over Dry Pot'
      },
      {cn: '蒜蓉大豆苗', en: 'Stir Fried Garlic Bean Sprouts'},
      {cn: '手撕包心菜', en: 'Stir Fried cabbage with special Hunan chili oil'},
      {cn: '家常豆腐', en: 'Dry Braised String Beans'},
      {cn: '蒜蓉西兰花', en: 'Stir Fried Garlic broccoli'},
      {cn: '满城尽带黄金甲', en: 'Soft-shelled turtle with speical sauce'},
      {cn: '鱼香茄子', en: 'Eggplant with special house sauce'},
      {
        cn: '干锅有机花菜',
        en: 'Organic Cauliflower & Smoked Ham w/ 4 Season Chiles'
      },
      {cn: '兰包粉丝', en: 'Cabbage w/ Vermicelli Sauteed in Hunan Sauce'},
      {cn: '红油牛百叶', en: 'Spicy red Chili oil! Beef blinds'},
      {cn: '夫妻肺片', en: 'Numbing Spicy! Beef Combo'},
      {cn: '口水鸡', en: 'Chicken with Red Oil'},
      {cn: '麻辣腰片', en: 'Numbing Spicy! Pork Kidney'},
      {cn: '凉拌干丝', en: 'Shredded Tofu with Sesame Oil'},
      {cn: '麻辣生肠', en: 'Numbing Spicy! Pork Intestine'},
      {cn: '红油牛筋', en: 'Spicy red Chili oil! Beef tendon'},
      {cn: '凉拌黄瓜', en: 'Marinated Cucumber with Sesame Oil'},
      {cn: '皮蛋豆腐', en: 'Thousand Year Old Egg with Cold Soft Tofu'},
      {cn: '成都伤心凉粉', en: 'Chengdu Jelly with spicy oil'},
      {cn: '核桃虾', en: 'Fried Walnut Prawns w/ House Cream Sauce'},
      {cn: '炸豆腐', en: 'Fried tofu'},
      {
        cn: '招牌烤鱼（香辣／蒜香／藤椒 ）',
        en: 'Sgnature premium grilled fish - Spicy, Ganilc, Sichuang pepper'
      },
      {cn: '绝代双椒走地鸡', en: 'Yellow Chicken with Special chill peppers'},
      {
        cn: '芽菜肉碎豆角荷叶包',
        en: 'Stir-fried Ground Green Beans with Ground Pork and Pickled Vegetable (Served with steamed folded Chinese Buns)'
      },
      {cn: '老油条牛肉', en: 'Beef Stir Fry with Chinese donut'},
      {cn: '重庆辣子鸡', en: 'Chicken Wings with w/ Explosive Chili Pepper'},
      {cn: '风味手抓羊排', en: 'Boiled mutton (Lamp chop)'},
      {cn: '羌锅爆辣腰花', en: 'Sautéed pork kidney'},
      {cn: '湘西野山椒牛肉丝', en: 'Shredded beef with Chili pepper'},
      {cn: '湘味小炒肉', en: 'Hredded Pork Stir Fried w/ Jalapeno & Cilantro'},
      {cn: '湖南剁椒蒸鱼', en: 'Steam fish with chipped peppers'},
      {cn: '湘味毛氏红烧肉', en: "Chairman Mao's Brown Braised Side Pork"},
      {cn: '湘西白辣椒牛肉', en: 'Beef with smoked White Hunan Chili'},
      {cn: '湘辣炒臭豆腐', en: 'Sticky tofu Pop Fried with Pork'},
      {cn: '洞庭湖魔芋烧鸭', en: "Heidi's favorites duck fried konjac"},
      {cn: '湘味小炒牛肉', en: 'Stir-fried beef with Hunan flavor'},
      {
        cn: '湘味小炒羊肉',
        en: 'Spicy Sliced Lamb Stir Fried w/ Chili & Cilantro'
      },
      {cn: '湘味辣炒鸡蛋', en: 'Spicy scrambled eggs'},
      {cn: '湘味辣炒土鸡', en: 'Free-Range Chicken w/ Bone in Hunan Spices'},
      {cn: '湘西香干腊肉', en: 'Hunan Smoked Ham with Bean Curd'},
      {cn: '湘西衡阳腐泡炖活鱼', en: 'Whole Fish Simmered with Tofu Soup'},
      {cn: '巴蜀酸菜鱼', en: 'Boiled Fish with Sichuan Pickles'},
      {cn: '重庆毛血旺', en: 'Beef Tripe & Pork Blood, Spam'},
      {cn: '重庆水煮鱼', en: 'Chongging fish bowl in Flaming Red Oil'},
      {cn: '川味鱼香肉丝', en: 'Chicken Intestine Sauteed w/ Garlic Sauce'},
      {cn: '川味宫保鸡丁', en: 'Kung Pao Chicken'},
      {cn: '藤椒鱼片', en: 'Boiled Fish Filled with Peppercorn Broth'},
      {cn: '干锅肥肠', en: 'Pork Intestine w/ 4 Season Chilies'},
      {
        cn: '老成都豆花肥牛',
        en: 'Old Chongging fatty beef slice with soft Tofe'
      },
      {
        cn: '川味韭香藤椒牛',
        en: 'Beef with leek and special sichuan chili pepper'
      },
      {cn: '成都香辣钵钵虾', en: 'Spicy "bobo" shrimp'},
      {cn: '巴蜀红烧猪蹄', en: 'Braised Pork Trotter'},
      {cn: '干锅牛筋肚', en: 'Beef Tendon & Tripe w/4 Season Chilies'},
      {cn: '四川干锅过瘾鸭头', en: 'Duck head w/4 Season Chilies'},
      {cn: '蜀国辣子鱼片', en: 'Fried Fish Fillet w/Explosive Chili Pepper'},
      {cn: '川味蒜苗腊肉', en: 'Smoked ham with Garlic sprouts'},
      {cn: '干锅肥牛', en: 'Sliced marble Beef w/ 4 Season Chilies'},
      {
        cn: '川味酸豆角肉末',
        en: 'Ground Pork Stir Fried w/ Pickled Green Beans'
      },
      {cn: '川味辣椒炒鸡', en: 'Hunan Chicken Bandit Style w/ Mixed Chili'},
      {cn: '蜀国火爆腰花', en: 'Pork Kidney with Hunan Spicy Sauce'},
      {cn: '酸菜猪肚汤', en: 'White Pepper Pork Stomach Pickles Soup'},
      {cn: '西湖牛肉羹', en: 'Minced Beef with Egg White Soup'},
      {cn: '鸡粒玉米羹', en: 'Corn Soup with Minced Chicken'},
      {cn: '养生滋补鸡汤', en: 'Chicken soup with Chinese Herb'},
      {cn: '青菜豆腐汤', en: 'Vegetable Tofu Soup'},
      {cn: '酸菜鱼片汤', en: 'Fish Fillet with Pickled Vegetables Soup'},
      {cn: '窝馄饨汤', en: 'Wor Wonton Soup'},
      {cn: '酸辣汤', en: 'Hot & Sour Soup'},
      {cn: '西红柿鸡蛋汤', en: 'Tomato egg soup'},
      {cn: '紫菜蛋花汤', en: 'Seaweed egg flower soup'},
      {cn: '白饭', en: 'Steamed Jasmine White Rice'},
      {cn: '扬州炒饭', en: 'Yangzhou Fried Rice'},
      {cn: '素炒饭', en: 'Vegetarian Fried Rice'},
      {cn: '湖南腊味炒饭', en: 'Smoked Ham Fried Rice'},
      {cn: '骚包炒饭', en: '"Bling Bling" Fried Rice w/ Shrimp/Beef/Chicken'},
      {cn: '辣妹子炒饭', en: 'SPiCESI Spicy Combination Fried Rice'},
      {cn: '酸豆角肉沫炒饭', en: 'Capers ground pork rice noodles'},
      {cn: '剁椒牛肉米粉', en: 'Beef rice noodles w/Hunan pepper'},
      {cn: '成都担担面', en: 'Chengdu Dandan noodles'},
      {cn: '鸡丝凉面', en: 'Cold noddles with chicken shreds'},
      {cn: '素炒面', en: 'Vegetarian Chow Mein'},
      {cn: '骚包炒面', en: '"Bling BlingChow Mein'}
    ]),
    image: {
      image2D: {
        url: getRandom([
          'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
          'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
          'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
          'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
          'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
          'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
          'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
          'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6'
        ]),
        path: '',
        bytes: 0,
        type: ''
      },
      image3D: {
        url: getRandom([
          'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
          'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
          'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
          'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
          'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
          'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
          'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
          'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6'
        ]),
        path: '',
        bytes: 0,
        type: ''
      }
    },
    counter: {
      totalSold: 10,
      monthSold: 8,
      weekSold: 4,
      daySold: 2
    },
    spiciness: getRandom([0, 1, 2, 3, 4]),
    price: Number((Math.random() * 50).toFixed(2)),
    status: getRandom(DISH_STATUS),
    discount: 0.95
  };
};

export const genCafeDish = (cafeId: string): CafeDish => {
  return {
    cafeId,
    dishId: genDishId(),
    dishMenuId: 0,
    name: {cn: '中文名', en: 'English Name'},
    spiciness: 0,
    status: 'popular',
    categories: [],
    price: 0,
    discount: 0,
    counter: {totalSold: 0, monthSold: 0, weekSold: 0, daySold: 0},
    image: {
      image2D: {url: '', path: '', type: '', bytes: 0},
      image3D: {url: '', path: '', type: '', bytes: 0}
    }
  };
};

export const genOrder = (): OrderData => {
  return {
    orderId: genOrderId(),
    cafeId: genCafeId(),
    cafeName: '青苹果',
    queueId: 100,
    tableId: 'A1',
    status: 'open',
    dishes: [],
    totalPrice: 0,
    guest: {note: '', phone: ''},
    created: new Date().getTime(),
    finished: 0
  };
};

export const genDishSample = (): DishSample => {
  return {
    sampleId: genDishSampleId(),
    name: {cn: '中文名', en: 'English Name'},
    image: {
      image2D: {url: '', path: '', type: '', bytes: 0},
      image3D: {url: '', path: '', type: '', bytes: 0}
    }
  };
};
