import {useAtom} from 'jotai';
import {
  dishOperationsAtom,
  dishSpicinessAtom
} from 'pages/OwnerPage/DishesPanel/EditDish/data';
import {memo} from 'react';
import {useDebouncedCallback} from 'use-debounce';

import {Card, InputBase, Stack, Typography} from '@mui/material';

function EditSpiciness() {
  const [dishSpiciness, setDishSpiciness] = useAtom(dishSpicinessAtom);
  const [, setOperations] = useAtom(dishOperationsAtom);

  const debounced = useDebouncedCallback(
    () => setOperations(_ => [..._, '保存辣度']),
    1000
  );
  const onChange = (e: any) => {
    setDishSpiciness(Number(e.target.value));
    debounced();
  };

  return (
    <Stack>
      <Typography variant='body2' fontWeight='bold'>
        辣度
      </Typography>
      <Card variant='outlined'>
        <InputBase
          value={Number(dishSpiciness)}
          onChange={onChange}
          fullWidth
          type='number'
          placeholder='辣度'
          sx={{bgcolor: 'action.hover', px: 1}}
        />
      </Card>
    </Stack>
  );
}

export default memo(EditSpiciness);
